@mixin focus {
  box-shadow: 0 0 6px 3px rgba(#52627c, 0.6);
  outline: 0;
}

// global variables
$transition-duration: 0.2s;
$transition-duration-slow: 1s;

// $icons-list: add alert arrow-left attach bank bullet calculator calendar camera chevron-down chevron-left chevron-right chevron-up clock close coins collaterals copy copy-down copy-right direction-down direction-left direction-right direction-up document download edit einnahmen-alimente einnahmen-leasing einnahmen-liegenschaft einnahmen-lohn einnahmen-rente einnahmen-vermoegen fast-forward filter funds grab growth help info investiture invisible letter-a letter-b letter-c lightbulb listing loading lock menu minus money money-income money-outlay pin play print product-overview profile refresh remove rename search settings slideshow sort-down sort-unsorted sort-up sorted-list sorted-tiles star status stop tick tick-circled tick-circled-outline tool tranches upload user user-child visible;

// $icon-colors-list: 'black' $black, 'blue' $blue-100, 'darkblue' $graphite-100, 'white' $atp-white;

// global definition of screen ranges
$sizes: 'xxxl' 1920 20000, 'xxl' 1440 1919, 'xl' 1280 1439, 'l' 1024 1279, 'm' 768 1023, 's' 568 767, 'xs' 0 567;

$responsive-lower-limit: 320px;

// spacing steps
$spacings: 'xxl' 240px, 'xl' 120px, 'l' 80px, 'm' 35px, 's' 25px, 'xs' 15px, 'xxs' 8px, 'xxxs' 4px, 'no' 0;

// list entry getter by content of first sub-child
@function get-list($label, $super-list) {
  @each $list in $super-list {
    @if nth($list, 1) == $label {
      @return $list;
    }
  }
  @return null;
}

// clear after
@mixin clearafter() {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    clear: both;
  }
}
.atp-clearafter {
  @include clearafter();
}

// line-height fix
@mixin lineheightfix() {
  &::after {
    content: '';
    font-family: Verdana, Arial, sans-serif;
  }
}

// placeholder selector-plug
@mixin input-placeholder() {
  // Chrome, Firefox, Opera, Safari 10.1+
  &::placeholder {
    @content;
    opacity: 1; // Firefox
  }

  // Internet Explorer 10-11
  &:-ms-input-placeholder {
    @content;
  }

  // Microsoft Edge
  // scss-lint:disable PseudoElement
  &::-ms-input-placeholder {
    @content;
  }
  // scss-lint:enable PseudoElement
}

// ellipsis text cropping
@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin reset-ellipsis() {
  white-space: normal;
  overflow: auto;
  text-overflow: none;
}

// outline none
@mixin outline-none() {
  outline: none;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}

// stroke reset
// scss-lint:disable DuplicateProperty ColorVariable
@mixin moz-inner-stroke-reset($color: #323232) {
  color: $color;
  color: rgba(#323232, 0);
  text-shadow: 0 0 0 $color;
}
// scss-lint:enable DuplicateProperty ColorVariable

@mixin disable-select {
  // scss-lint:disable VendorPrefixes
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // scss-lint:enable VendorPrefixes
}

// secondary text format
$secondary-text-color: #6d6d6d;

@mixin secondary-text() {
  color: $secondary-text-color;
  @include font-weight-regular;
  font-size: 14px;
  line-height: 20px;
}
