@mixin sidebar-background-color($background-color, $font-color) {
  .nav {
    li {
      > a {
        color: $font-color;
      }

      i {
        color: rgba($font-color, 0.8);
      }

      &.active,
      &:hover {
        > [data-toggle='collapse'] {
          color: $font-color;

          i {
            color: rgba($font-color, 0.8);
          }
        }
      }
    }
  }

  .user {
    a {
      color: $font-color;
    }
  }

  .simple-text {
    color: $font-color;
  }

  .sidebar-background::after {
    background: $background-color;
    opacity: 0.8;
  }
}

@mixin sidebar-active-color($font-color) {
  .nav {
    li {
      &.active > a:not([data-toggle='collapse']) {
        @include shadow-big-color($font-color);
        color: $font-color;
        opacity: 1;

        i {
          color: rgba($font-color, 0.8);
        }
      }
    }
  }
}
