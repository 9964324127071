// NOTE: https://github.com/JCornat/material-icons-css/blob/master/scss/material-icons.scss

$icons: (
  '3d-rotation': e84d,
  'ac-unit': eb3b,
  'access-alarm': e190,
  'access-alarms': e191,
  'access-time': e192,
  'accessibility': e84e,
  'accessible': e914,
  'account-balance': e84f,
  'account-balance-wallet': e850,
  'account-box': e851,
  'account-circle': e853,
  'adb': e60e,
  'add': e145,
  'add-a-photo': e439,
  'add-alarm': e193,
  'add-alert': e003,
  'add-box': e146,
  'add-circle': e147,
  'add-circle-outline': e148,
  'add-location': e567,
  'add-shopping-cart': e854,
  'add-to-photos': e39d,
  'add-to-queue': e05c,
  'adjust': e39e,
  'airline-seat-flat': e630,
  'airline-seat-flat-angled': e631,
  'airline-seat-individual-suite': e632,
  'airline-seat-legroom-extra': e633,
  'airline-seat-legroom-normal': e634,
  'airline-seat-legroom-reduced': e635,
  'airline-seat-recline-extra': e636,
  'airline-seat-recline-normal': e637,
  'airplanemode-active': e195,
  'airplanemode-inactive': e194,
  'airplay': e055,
  'airport-shuttle': eb3c,
  'alarm': e855,
  'alarm-add': e856,
  'alarm-off': e857,
  'alarm-on': e858,
  'album': e019,
  'all-inclusive': eb3d,
  'all-out': e90b,
  'android': e859,
  'announcement': e85a,
  'apps': e5c3,
  'archive': e149,
  'arrow-back': e5c4,
  'arrow-downward': e5db,
  'arrow-drop-down': e5c5,
  'arrow-drop-down-circle': e5c6,
  'arrow-drop-up': e5c7,
  'arrow-forward': e5c8,
  'arrow-upward': e5d8,
  'art-track': e060,
  'aspect-ratio': e85b,
  'assessment': e85c,
  'assignment': e85d,
  'assignment-ind': e85e,
  'assignment-late': e85f,
  'assignment-return': e860,
  'assignment-returned': e861,
  'assignment-turned-in': e862,
  'assistant': e39f,
  'assistant-photo': e3a0,
  'attach-file': e226,
  'attach-money': e227,
  'attachment': e2bc,
  'audiotrack': e3a1,
  'autorenew': e863,
  'av-timer': e01b,
  'backspace': e14a,
  'backup': e864,
  'battery-alert': e19c,
  'battery-charging-full': e1a3,
  'battery-full': e1a4,
  'battery-std': e1a5,
  'battery-unknown': e1a6,
  'beach-access': eb3e,
  'beenhere': e52d,
  'block': e14b,
  'bluetooth': e1a7,
  'bluetooth-audio': e60f,
  'bluetooth-connected': e1a8,
  'bluetooth-disabled': e1a9,
  'bluetooth-searching': e1aa,
  'blur-circular': e3a2,
  'blur-linear': e3a3,
  'blur-off': e3a4,
  'blur-on': e3a5,
  'book': e865,
  'bookmark': e866,
  'bookmark-border': e867,
  'border-all': e228,
  'border-bottom': e229,
  'border-clear': e22a,
  'border-color': e22b,
  'border-horizontal': e22c,
  'border-inner': e22d,
  'border-left': e22e,
  'border-outer': e22f,
  'border-right': e230,
  'border-style': e231,
  'border-top': e232,
  'border-vertical': e233,
  'branding-watermark': e06b,
  'brightness-1': e3a6,
  'brightness-2': e3a7,
  'brightness-3': e3a8,
  'brightness-4': e3a9,
  'brightness-5': e3aa,
  'brightness-6': e3ab,
  'brightness-7': e3ac,
  'brightness-auto': e1ab,
  'brightness-high': e1ac,
  'brightness-low': e1ad,
  'brightness-medium': e1ae,
  'broken-image': e3ad,
  'brush': e3ae,
  'bubble-chart': e6dd,
  'bug-report': e868,
  'build': e869,
  'burst-mode': e43c,
  'business': e0af,
  'business-center': eb3f,
  'cached': e86a,
  'cake': e7e9,
  'call': e0b0,
  'call-end': e0b1,
  'call-made': e0b2,
  'call-merge': e0b3,
  'call-missed': e0b4,
  'call-missed-outgoing': e0e4,
  'call-received': e0b5,
  'call-split': e0b6,
  'call-to-action': e06c,
  'camera': e3af,
  'camera-alt': e3b0,
  'camera-enhance': e8fc,
  'camera-front': e3b1,
  'camera-rear': e3b2,
  'camera-roll': e3b3,
  'cancel': e5c9,
  'card-giftcard': e8f6,
  'card-membership': e8f7,
  'card-travel': e8f8,
  'casino': eb40,
  'cast': e307,
  'cast-connected': e308,
  'center-focus-strong': e3b4,
  'center-focus-weak': e3b5,
  'change-history': e86b,
  'chat': e0b7,
  'chat-bubble': e0ca,
  'chat-bubble-outline': e0cb,
  'check': e5ca,
  'check-box': e834,
  'check-box-outline-blank': e835,
  'check-circle': e86c,
  'chevron-left': e5cb,
  'chevron-right': e5cc,
  'child-care': eb41,
  'child-friendly': eb42,
  'chrome-reader-mode': e86d,
  'class': e86e,
  'clear': e14c,
  'clear-all': e0b8,
  'close': e5cd,
  'closed-caption': e01c,
  'cloud': e2bd,
  'cloud-circle': e2be,
  'cloud-done': e2bf,
  'cloud-download': e2c0,
  'cloud-off': e2c1,
  'cloud-queue': e2c2,
  'cloud-upload': e2c3,
  'code': e86f,
  'collections': e3b6,
  'collections-bookmark': e431,
  'color-lens': e3b7,
  'colorize': e3b8,
  'comment': e0b9,
  'compare': e3b9,
  'compare-arrows': e915,
  'computer': e30a,
  'confirmation-number': e638,
  'contact-mail': e0d0,
  'contact-phone': e0cf,
  'contacts': e0ba,
  'content-copy': e14d,
  'content-cut': e14e,
  'content-paste': e14f,
  'control-point': e3ba,
  'control-point-duplicate': e3bb,
  'copyright': e90c,
  'create': e150,
  'create-new-folder': e2cc,
  'credit-card': e870,
  'crop': e3be,
  'crop-16-9': e3bc,
  'crop-3-2': e3bd,
  'crop-5-4': e3bf,
  'crop-7-5': e3c0,
  'crop-din': e3c1,
  'crop-free': e3c2,
  'crop-landscape': e3c3,
  'crop-original': e3c4,
  'crop-portrait': e3c5,
  'crop-rotate': e437,
  'crop-square': e3c6,
  'dashboard': e871,
  'data-usage': e1af,
  'date-range': e916,
  'dehaze': e3c7,
  'delete': e872,
  'delete-forever': e92b,
  'delete-sweep': e16c,
  'description': e873,
  'desktop-mac': e30b,
  'desktop-windows': e30c,
  'details': e3c8,
  'developer-board': e30d,
  'developer-mode': e1b0,
  'device-hub': e335,
  'devices': e1b1,
  'devices-other': e337,
  'dialer-sip': e0bb,
  'dialpad': e0bc,
  'directions': e52e,
  'directions-bike': e52f,
  'directions-boat': e532,
  'directions-bus': e530,
  'directions-car': e531,
  'directions-railway': e534,
  'directions-run': e566,
  'directions-subway': e533,
  'directions-transit': e535,
  'directions-walk': e536,
  'disc-full': e610,
  'dns': e875,
  'do-not-disturb': e612,
  'do-not-disturb-alt': e611,
  'do-not-disturb-off': e643,
  'do-not-disturb-on': e644,
  'dock': e30e,
  'domain': e7ee,
  'done': e876,
  'done-all': e877,
  'donut-large': e917,
  'donut-small': e918,
  'drafts': e151,
  'drag-handle': e25d,
  'drive-eta': e613,
  'dvr': e1b2,
  'edit': e3c9,
  'edit-location': e568,
  'eject': e8fb,
  'email': e0be,
  'enhanced-encryption': e63f,
  'equalizer': e01d,
  'error': e000,
  'error-outline': e001,
  'euro-symbol': e926,
  'ev-station': e56d,
  'event': e878,
  'event-available': e614,
  'event-busy': e615,
  'event-note': e616,
  'event-seat': e903,
  'exit-to-app': e879,
  'expand-less': e5ce,
  'expand-more': e5cf,
  'explicit': e01e,
  'explore': e87a,
  'exposure': e3ca,
  'exposure-neg-1': e3cb,
  'exposure-neg-2': e3cc,
  'exposure-plus-1': e3cd,
  'exposure-plus-2': e3ce,
  'exposure-zero': e3cf,
  'extension': e87b,
  'face': e87c,
  'fast-forward': e01f,
  'fast-rewind': e020,
  'favorite': e87d,
  'favorite-border': e87e,
  'featured-play-list': e06d,
  'featured-video': e06e,
  'feedback': e87f,
  'fiber-dvr': e05d,
  'fiber-manual-record': e061,
  'fiber-new': e05e,
  'fiber-pin': e06a,
  'fiber-smart-record': e062,
  'file-download': e2c4,
  'file-upload': e2c6,
  'filter': e3d3,
  'filter-1': e3d0,
  'filter-2': e3d1,
  'filter-3': e3d2,
  'filter-4': e3d4,
  'filter-5': e3d5,
  'filter-6': e3d6,
  'filter-7': e3d7,
  'filter-8': e3d8,
  'filter-9': e3d9,
  'filter-9-plus': e3da,
  'filter-b-and-w': e3db,
  'filter-center-focus': e3dc,
  'filter-drama': e3dd,
  'filter-frames': e3de,
  'filter-hdr': e3df,
  'filter-list': e152,
  'filter-none': e3e0,
  'filter-tilt-shift': e3e2,
  'filter-vintage': e3e3,
  'find-in-page': e880,
  'find-replace': e881,
  'fingerprint': e90d,
  'first-page': e5dc,
  'fitness-center': eb43,
  'flag': e153,
  'flare': e3e4,
  'flash-auto': e3e5,
  'flash-off': e3e6,
  'flash-on': e3e7,
  'flight': e539,
  'flight-land': e904,
  'flight-takeoff': e905,
  'flip': e3e8,
  'flip-to-back': e882,
  'flip-to-front': e883,
  'folder': e2c7,
  'folder-open': e2c8,
  'folder-shared': e2c9,
  'folder-special': e617,
  'font-download': e167,
  'format-align-center': e234,
  'format-align-justify': e235,
  'format-align-left': e236,
  'format-align-right': e237,
  'format-bold': e238,
  'format-clear': e239,
  'format-color-fill': e23a,
  'format-color-reset': e23b,
  'format-color-text': e23c,
  'format-indent-decrease': e23d,
  'format-indent-increase': e23e,
  'format-italic': e23f,
  'format-line-spacing': e240,
  'format-list-bulleted': e241,
  'format-list-numbered': e242,
  'format-paint': e243,
  'format-quote': e244,
  'format-shapes': e25e,
  'format-size': e245,
  'format-strikethrough': e246,
  'format-textdirection-l-to-r': e247,
  'format-textdirection-r-to-l': e248,
  'format-underlined': e249,
  'forum': e0bf,
  'forward': e154,
  'forward-10': e056,
  'forward-30': e057,
  'forward-5': e058,
  'free-breakfast': eb44,
  'fullscreen': e5d0,
  'fullscreen-exit': e5d1,
  'functions': e24a,
  'g-translate': e927,
  'gamepad': e30f,
  'games': e021,
  'gavel': e90e,
  'gesture': e155,
  'get-app': e884,
  'gif': e908,
  'golf-course': eb45,
  'gps-fixed': e1b3,
  'gps-not-fixed': e1b4,
  'gps-off': e1b5,
  'grade': e885,
  'gradient': e3e9,
  'grain': e3ea,
  'graphic-eq': e1b8,
  'grid-off': e3eb,
  'grid-on': e3ec,
  'group': e7ef,
  'group-add': e7f0,
  'group-work': e886,
  'hd': e052,
  'hdr-off': e3ed,
  'hdr-on': e3ee,
  'hdr-strong': e3f1,
  'hdr-weak': e3f2,
  'headset': e310,
  'headset-mic': e311,
  'healing': e3f3,
  'hearing': e023,
  'help': e887,
  'help-outline': e8fd,
  'high-quality': e024,
  'highlight': e25f,
  'highlight-off': e888,
  'history': e889,
  'home': e88a,
  'hot-tub': eb46,
  'hotel': e53a,
  'hourglass-empty': e88b,
  'hourglass-full': e88c,
  'http': e902,
  'https': e88d,
  'image': e3f4,
  'image-aspect-ratio': e3f5,
  'import-contacts': e0e0,
  'import-export': e0c3,
  'important-devices': e912,
  'inbox': e156,
  'indeterminate-check-box': e909,
  'info': e88e,
  'info-outline': e88f,
  'input': e890,
  'insert-chart': e24b,
  'insert-comment': e24c,
  'insert-drive-file': e24d,
  'insert-emoticon': e24e,
  'insert-invitation': e24f,
  'insert-link': e250,
  'insert-photo': e251,
  'invert-colors': e891,
  'invert-colors-off': e0c4,
  'iso': e3f6,
  'keyboard': e312,
  'keyboard-arrow-down': e313,
  'keyboard-arrow-left': e314,
  'keyboard-arrow-right': e315,
  'keyboard-arrow-up': e316,
  'keyboard-backspace': e317,
  'keyboard-capslock': e318,
  'keyboard-hide': e31a,
  'keyboard-return': e31b,
  'keyboard-tab': e31c,
  'keyboard-voice': e31d,
  'kitchen': eb47,
  'label': e892,
  'label-outline': e893,
  'landscape': e3f7,
  'language': e894,
  'laptop': e31e,
  'laptop-chromebook': e31f,
  'laptop-mac': e320,
  'laptop-windows': e321,
  'last-page': e5dd,
  'launch': e895,
  'layers': e53b,
  'layers-clear': e53c,
  'leak-add': e3f8,
  'leak-remove': e3f9,
  'lens': e3fa,
  'library-add': e02e,
  'library-books': e02f,
  'library-music': e030,
  'lightbulb-outline': e90f,
  'line-style': e919,
  'line-weight': e91a,
  'linear-scale': e260,
  'link': e157,
  'linked-camera': e438,
  'list': e896,
  'live-help': e0c6,
  'live-tv': e639,
  'local-activity': e53f,
  'local-airport': e53d,
  'local-atm': e53e,
  'local-bar': e540,
  'local-cafe': e541,
  'local-car-wash': e542,
  'local-convenience-store': e543,
  'local-dining': e556,
  'local-drink': e544,
  'local-florist': e545,
  'local-gas-station': e546,
  'local-grocery-store': e547,
  'local-hospital': e548,
  'local-hotel': e549,
  'local-laundry-service': e54a,
  'local-library': e54b,
  'local-mall': e54c,
  'local-movies': e54d,
  'local-offer': e54e,
  'local-parking': e54f,
  'local-pharmacy': e550,
  'local-phone': e551,
  'local-pizza': e552,
  'local-play': e553,
  'local-post-office': e554,
  'local-printshop': e555,
  'local-see': e557,
  'local-shipping': e558,
  'local-taxi': e559,
  'location-city': e7f1,
  'location-disabled': e1b6,
  'location-off': e0c7,
  'location-on': e0c8,
  'location-searching': e1b7,
  'lock': e897,
  'lock-open': e898,
  'lock-outline': e899,
  'looks': e3fc,
  'looks-3': e3fb,
  'looks-4': e3fd,
  'looks-5': e3fe,
  'looks-6': e3ff,
  'looks-one': e400,
  'looks-two': e401,
  'loop': e028,
  'loupe': e402,
  'low-priority': e16d,
  'loyalty': e89a,
  'mail': e158,
  'mail-outline': e0e1,
  'map': e55b,
  'markunread': e159,
  'markunread-mailbox': e89b,
  'memory': e322,
  'menu': e5d2,
  'merge-type': e252,
  'message': e0c9,
  'mic': e029,
  'mic-none': e02a,
  'mic-off': e02b,
  'mms': e618,
  'mode-comment': e253,
  'mode-edit': e254,
  'monetization-on': e263,
  'money-off': e25c,
  'monochrome-photos': e403,
  'mood': e7f2,
  'mood-bad': e7f3,
  'more': e619,
  'more-horiz': e5d3,
  'more-vert': e5d4,
  'motorcycle': e91b,
  'mouse': e323,
  'move-to-inbox': e168,
  'movie': e02c,
  'movie-creation': e404,
  'movie-filter': e43a,
  'multiline-chart': e6df,
  'music-note': e405,
  'music-video': e063,
  'my-location': e55c,
  'nature': e406,
  'nature-people': e407,
  'navigate-before': e408,
  'navigate-next': e409,
  'navigation': e55d,
  'near-me': e569,
  'network-cell': e1b9,
  'network-check': e640,
  'network-locked': e61a,
  'network-wifi': e1ba,
  'new-releases': e031,
  'next-week': e16a,
  'nfc': e1bb,
  'no-encryption': e641,
  'no-sim': e0cc,
  'not-interested': e033,
  'note': e06f,
  'note-add': e89c,
  'notifications': e7f4,
  'notifications-active': e7f7,
  'notifications-none': e7f5,
  'notifications-off': e7f6,
  'notifications-paused': e7f8,
  'offline-pin': e90a,
  'ondemand-video': e63a,
  'opacity': e91c,
  'open-in-browser': e89d,
  'open-in-new': e89e,
  'open-with': e89f,
  'pages': e7f9,
  'pageview': e8a0,
  'palette': e40a,
  'pan-tool': e925,
  'panorama': e40b,
  'panorama-fish-eye': e40c,
  'panorama-horizontal': e40d,
  'panorama-vertical': e40e,
  'panorama-wide-angle': e40f,
  'party-mode': e7fa,
  'pause': e034,
  'pause-circle-filled': e035,
  'pause-circle-outline': e036,
  'payment': e8a1,
  'people': e7fb,
  'people-outline': e7fc,
  'perm-camera-mic': e8a2,
  'perm-contact-calendar': e8a3,
  'perm-data-setting': e8a4,
  'perm-device-information': e8a5,
  'perm-identity': e8a6,
  'perm-media': e8a7,
  'perm-phone-msg': e8a8,
  'perm-scan-wifi': e8a9,
  'person': e7fd,
  'person-add': e7fe,
  'person-outline': e7ff,
  'person-pin': e55a,
  'person-pin-circle': e56a,
  'personal-video': e63b,
  'pets': e91d,
  'phone': e0cd,
  'phone-android': e324,
  'phone-bluetooth-speaker': e61b,
  'phone-forwarded': e61c,
  'phone-in-talk': e61d,
  'phone-iphone': e325,
  'phone-locked': e61e,
  'phone-missed': e61f,
  'phone-paused': e620,
  'phonelink': e326,
  'phonelink-erase': e0db,
  'phonelink-lock': e0dc,
  'phonelink-off': e327,
  'phonelink-ring': e0dd,
  'phonelink-setup': e0de,
  'photo': e410,
  'photo-album': e411,
  'photo-camera': e412,
  'photo-filter': e43b,
  'photo-library': e413,
  'photo-size-select-actual': e432,
  'photo-size-select-large': e433,
  'photo-size-select-small': e434,
  'picture-as-pdf': e415,
  'picture-in-picture': e8aa,
  'picture-in-picture-alt': e911,
  'pie-chart': e6c4,
  'pie-chart-outlined': e6c5,
  'pin-drop': e55e,
  'place': e55f,
  'play-arrow': e037,
  'play-circle-filled': e038,
  'play-circle-outline': e039,
  'play-for-work': e906,
  'playlist-add': e03b,
  'playlist-add-check': e065,
  'playlist-play': e05f,
  'plus-one': e800,
  'poll': e801,
  'polymer': e8ab,
  'pool': eb48,
  'portable-wifi-off': e0ce,
  'portrait': e416,
  'power': e63c,
  'power-input': e336,
  'power-settings-new': e8ac,
  'pregnant-woman': e91e,
  'present-to-all': e0df,
  'print': e8ad,
  'priority-high': e645,
  'public': e80b,
  'publish': e255,
  'query-builder': e8ae,
  'question-answer': e8af,
  'queue': e03c,
  'queue-music': e03d,
  'queue-play-next': e066,
  'radio': e03e,
  'radio-button-checked': e837,
  'radio-button-unchecked': e836,
  'rate-review': e560,
  'receipt': e8b0,
  'recent-actors': e03f,
  'record-voice-over': e91f,
  'redeem': e8b1,
  'redo': e15a,
  'refresh': e5d5,
  'remove': e15b,
  'remove-circle': e15c,
  'remove-circle-outline': e15d,
  'remove-from-queue': e067,
  'remove-red-eye': e417,
  'remove-shopping-cart': e928,
  'reorder': e8fe,
  'repeat': e040,
  'repeat-one': e041,
  'replay': e042,
  'replay-10': e059,
  'replay-30': e05a,
  'replay-5': e05b,
  'reply': e15e,
  'reply-all': e15f,
  'report': e160,
  'report-problem': e8b2,
  'restaurant': e56c,
  'restaurant-menu': e561,
  'restore': e8b3,
  'restore-page': e929,
  'ring-volume': e0d1,
  'room': e8b4,
  'room-service': eb49,
  'rotate-90-degrees-ccw': e418,
  'rotate-left': e419,
  'rotate-right': e41a,
  'rounded-corner': e920,
  'router': e328,
  'rowing': e921,
  'rss-feed': e0e5,
  'rv-hookup': e642,
  'satellite': e562,
  'save': e161,
  'scanner': e329,
  'schedule': e8b5,
  'school': e80c,
  'screen-lock-landscape': e1be,
  'screen-lock-portrait': e1bf,
  'screen-lock-rotation': e1c0,
  'screen-rotation': e1c1,
  'screen-share': e0e2,
  'sd-card': e623,
  'sd-storage': e1c2,
  'search': e8b6,
  'security': e32a,
  'select-all': e162,
  'send': e163,
  'sentiment-dissatisfied': e811,
  'sentiment-neutral': e812,
  'sentiment-satisfied': e813,
  'sentiment-very-dissatisfied': e814,
  'sentiment-very-satisfied': e815,
  'settings': e8b8,
  'settings-applications': e8b9,
  'settings-backup-restore': e8ba,
  'settings-bluetooth': e8bb,
  'settings-brightness': e8bd,
  'settings-cell': e8bc,
  'settings-ethernet': e8be,
  'settings-input-antenna': e8bf,
  'settings-input-component': e8c0,
  'settings-input-composite': e8c1,
  'settings-input-hdmi': e8c2,
  'settings-input-svideo': e8c3,
  'settings-overscan': e8c4,
  'settings-phone': e8c5,
  'settings-power': e8c6,
  'settings-remote': e8c7,
  'settings-system-daydream': e1c3,
  'settings-voice': e8c8,
  'share': e80d,
  'shop': e8c9,
  'shop-two': e8ca,
  'shopping-basket': e8cb,
  'shopping-cart': e8cc,
  'short-text': e261,
  'show-chart': e6e1,
  'shuffle': e043,
  'signal-cellular-4-bar': e1c8,
  'signal-cellular-connected-no-internet-4-bar': e1cd,
  'signal-cellular-no-sim': e1ce,
  'signal-cellular-null': e1cf,
  'signal-cellular-off': e1d0,
  'signal-wifi-4-bar': e1d8,
  'signal-wifi-4-bar-lock': e1d9,
  'signal-wifi-off': e1da,
  'sim-card': e32b,
  'sim-card-alert': e624,
  'skip-next': e044,
  'skip-previous': e045,
  'slideshow': e41b,
  'slow-motion-video': e068,
  'smartphone': e32c,
  'smoke-free': eb4a,
  'smoking-rooms': eb4b,
  'sms': e625,
  'sms-failed': e626,
  'snooze': e046,
  'sort': e164,
  'sort-by-alpha': e053,
  'spa': eb4c,
  'space-bar': e256,
  'speaker': e32d,
  'speaker-group': e32e,
  'speaker-notes': e8cd,
  'speaker-notes-off': e92a,
  'speaker-phone': e0d2,
  'spellcheck': e8ce,
  'star': e838,
  'star-border': e83a,
  'star-half': e839,
  'stars': e8d0,
  'stay-current-landscape': e0d3,
  'stay-current-portrait': e0d4,
  'stay-primary-landscape': e0d5,
  'stay-primary-portrait': e0d6,
  'stop': e047,
  'stop-screen-share': e0e3,
  'storage': e1db,
  'store': e8d1,
  'store-mall-directory': e563,
  'straighten': e41c,
  'streetview': e56e,
  'strikethrough-s': e257,
  'style': e41d,
  'subdirectory-arrow-left': e5d9,
  'subdirectory-arrow-right': e5da,
  'subject': e8d2,
  'subscriptions': e064,
  'subtitles': e048,
  'subway': e56f,
  'supervisor-account': e8d3,
  'surround-sound': e049,
  'swap-calls': e0d7,
  'swap-horiz': e8d4,
  'swap-vert': e8d5,
  'swap-vertical-circle': e8d6,
  'switch-camera': e41e,
  'switch-video': e41f,
  'sync': e627,
  'sync-disabled': e628,
  'sync-problem': e629,
  'system-update': e62a,
  'system-update-alt': e8d7,
  'tab': e8d8,
  'tab-unselected': e8d9,
  'tablet': e32f,
  'tablet-android': e330,
  'tablet-mac': e331,
  'tag-faces': e420,
  'tap-and-play': e62b,
  'terrain': e564,
  'text-fields': e262,
  'text-format': e165,
  'textsms': e0d8,
  'texture': e421,
  'theaters': e8da,
  'thumb-down': e8db,
  'thumb-up': e8dc,
  'thumbs-up-down': e8dd,
  'time-to-leave': e62c,
  'timelapse': e422,
  'timeline': e922,
  'timer': e425,
  'timer-10': e423,
  'timer-3': e424,
  'timer-off': e426,
  'title': e264,
  'toc': e8de,
  'today': e8df,
  'toll': e8e0,
  'tonality': e427,
  'touch-app': e913,
  'toys': e332,
  'track-changes': e8e1,
  'traffic': e565,
  'train': e570,
  'tram': e571,
  'transfer-within-a-station': e572,
  'transform': e428,
  'translate': e8e2,
  'trending-down': e8e3,
  'trending-flat': e8e4,
  'trending-up': e8e5,
  'tune': e429,
  'turned-in': e8e6,
  'turned-in-not': e8e7,
  'tv': e333,
  'unarchive': e169,
  'undo': e166,
  'unfold-less': e5d6,
  'unfold-more': e5d7,
  'update': e923,
  'usb': e1e0,
  'verified-user': e8e8,
  'vertical-align-bottom': e258,
  'vertical-align-center': e259,
  'vertical-align-top': e25a,
  'vibration': e62d,
  'video-call': e070,
  'video-label': e071,
  'video-library': e04a,
  'videocam': e04b,
  'videocam-off': e04c,
  'videogame-asset': e338,
  'view-agenda': e8e9,
  'view-array': e8ea,
  'view-carousel': e8eb,
  'view-column': e8ec,
  'view-comfy': e42a,
  'view-compact': e42b,
  'view-day': e8ed,
  'view-headline': e8ee,
  'view-list': e8ef,
  'view-module': e8f0,
  'view-quilt': e8f1,
  'view-stream': e8f2,
  'view-week': e8f3,
  'vignette': e435,
  'visibility': e8f4,
  'visibility-off': e8f5,
  'voice-chat': e62e,
  'voicemail': e0d9,
  'volume-down': e04d,
  'volume-mute': e04e,
  'volume-off': e04f,
  'volume-up': e050,
  'vpn-key': e0da,
  'vpn-lock': e62f,
  'wallpaper': e1bc,
  'warning': e002,
  'watch': e334,
  'watch-later': e924,
  'wb-auto': e42c,
  'wb-cloudy': e42d,
  'wb-incandescent': e42e,
  'wb-iridescent': e436,
  'wb-sunny': e430,
  'wc': e63d,
  'web': e051,
  'web-asset': e069,
  'weekend': e16b,
  'whatshot': e80e,
  'widgets': e1bd,
  'wifi': e63e,
  'wifi-lock': e1e1,
  'wifi-tethering': e1e2,
  'work': e8f9,
  'wrap-text': e25b,
  'youtube-searched-for': e8fa,
  'zoom-in': e8ff,
  'zoom-out': e900,
  'zoom-out-map': e56b,
);

$prefix: 'material-icons'; // PERSONALIZE YOUR PREFIX

@mixin icon($prefix, $name, $code) {
  $withslash: '"\\#{$code}"';

  .#{$prefix}-#{$name}::before {
    content: unquote($withslash);
  }
}

@each $key, $value in $icons {
  @include icon($prefix, $key, $value);
}
