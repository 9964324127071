@mixin mdb-label-color-toggle-focus() {
  // override bootstrap focus and keep all the standard color (could be multiple radios in the form group)
  .form-group.is-focused & {
    color: $mdb-label-color;

    // on focus just darken the specific labels, do not turn them to the brand-primary
    &:hover,
    &:focus {
      color: $mdb-label-color-toggle-focus;
    }

    // correct the above focus color for disabled items
    fieldset[disabled] & {
      color: $mdb-label-color;
    }
  }
}

.form-category {
  padding: 10px 0;

  .checkbox {
    margin: 0;
  }
}

.form-group {
  &.form-checkbox {
    padding-top: 10px;
  }
}

form {
  .form-footer {
    .checkbox {
      padding-top: 5px;
    }
  }
}

.form-horizontal {
  .card & {
    .label-on-left {
      padding: 28px 5px 0 0;
      text-align: right;
    }

    .label-on-right {
      padding: 28px 0 0 5px;
      text-align: left;
    }
  }

  .form-horizontal-checkbox {
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .checkbox-radios {
    .checkbox,
    .radio {
      &:first-child {
        margin-top: 16px;
      }
    }

    .radio {
      padding-top: 10px;
    }
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }

  .form-button {
    padding: 0;
    margin: 0;
  }

  .checkbox-inline {
    margin-top: 16px;
    padding-left: 0;
  }

  .radio label {
    padding-left: 28px;

    span {
      left: 2px;
    }
  }

  label.control-label {
    margin: 0;
  }

  .form-control[type='password'] {
    padding-top: 8px;
    padding-bottom: 6px;
  }
}

.form-newsletter {
  .input-group,
  .form-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
  }

  .btn {
    float: left;
    width: 20%;
    margin: 9px 0 0;
  }
}
