// colors for webform components
$webform-shadow-color: #000;
$webform-sortable-list-background-color: #e0e0e0;
$webform-input-prefix: #979797;
$webform-drag-placeholder: #fafafa;
$webform-add-more: #757575;
$webform-add-more-icon: #979797;
$webform-shadow-color-hover: #7252ac;
$webform-input-underline-color: #624c96;
$webform-mention-bg: rgba(#707070, 0.36);
$webform-input: #9e9e9e;
$webform-input-active: #624c96;
$webform-item-focused: #cee4e5;
$webform-element-background-color: #fff;

// colors for mentions components
$mentions-input-suggestions-item-focused-background-color: #7252ac;
$mentions-input-suggestions-item-focused-color: #fff;

// Uploader preview
$uploader-c-link: #624c96;
$uploader-c-link-hover: #3f2a73;
$uploader-c-link-active: #24134f;

$uploaded-c-drop-border: #ccc;
$uploaded-c-drop-text: #828282;

.webform {
  padding-left: 0;
  padding-right: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //ui card override for the webform screen
  .card .card-content {
    padding: 20px 0;

    > .form-group {
      margin: 0 15px;
    }
  }

  // webform__sortable-list component
  &__sortable-list {
    background-color: $webform-sortable-list-background-color;
  }

  .mention_label {
    color: $webform-input-prefix;
  }

  // fixed toolbar on the side of the form, with buttons for adding blocks
  &__toolbar {
    margin-top: 16px;
    position: absolute;
    z-index: 1;

    &.affix {
      top: -11px;
      position: fixed;
    }

    > .btn-group-vertical {
      display: block;
    }
  }
}

// webform-element, component
.webform-element {
  background-color: $webform-element-background-color;
  padding-bottom: 30px;
  position: relative;
  transition: background-color box-shadow 0.15s;

  > .row {
    padding-left: 15px;
    padding-right: 15px;
  }

  // when hover and not a --selected item
  &:hover {
    background-color: #f9f9f9;
  }

  // webform-element--selected, component modifier
  &--selected {
    box-shadow: -3px 0 0 $webform-shadow-color-hover;
    z-index: 3;
  }

  &--selected & {
    &__drag-handle {
      opacity: 1;
    }
  }

  // webform-element--selected, component modifier, when hover
  &--selected:hover {
    background-color: #fff;
    box-shadow: -3px 0 0 $webform-shadow-color-hover;
  }

  // sub-component: webform-element__drag-handle
  &__drag-handle {
    @include grab-cursor;
    opacity: 0;
    height: 30px;
    text-align: center;

    &__icon {
      line-height: 30px;
    }

    &:active {
      @include grabbing-cursor;
    }
  }

  // webform-element__actions component
  &__actions {
    position: absolute;
    right: 0;
    bottom: 0;

    .togglebutton {
      display: inline-block;
    }

    .btn {
      margin: 0;
      top: -10px;
    }
  }

  fieldset {
    padding: 0;
    border: 0;
  }

  // drop zone for files
  .file-uploader-drop {
    border: 1px dashed $uploaded-c-drop-border;
    background-image: url('../../images/custom/file-uploader.svg');
    background-position: center 19px;
    background-repeat: no-repeat;
    margin: 0 0 21px;
    padding: 50px 0 17px;
    line-height: 15px;
    text-align: center;
    color: $uploaded-c-drop-text;
    cursor: pointer;
    position: relative;

    [type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      text-indent: -3000px;
      cursor: pointer;
    }

    a {
      line-height: 15px;
      color: $uploader-c-link;
      border-color: $uploader-c-link;
    }
  }
}

// specialized class for handling the draggable copy of the sortable element into the body tag
// for more info about this class see:
// https://github.com/clauderic/react-sortable-hoc#item-disappearing-when-sorting--css-issues
// https://github.com/clauderic/react-sortable-hoc/issues/87
.webform-element-sortable-helper {
  box-shadow: 0 10px 30px rgba($webform-shadow-color, 0.15),
    -3px 0 0 rgba($webform-shadow-color-hover, 1);
  transition: box-shadow 0.15s;
  z-index: 3;

  .webform-element__drag-handle {
    opacity: 1;
  }

  .togglebutton .toggle ~ .toggle {
    display: none;
  }

  .checkbox .checkbox-material ~ .checkbox-material,
  .checkbox .checkbox-material::before {
    display: none;
  }
}

// generic elements wrapping
.webform-radios-input,
.webform-checkboxes-input,
.webform-string-input,
.webform-text-input,
.webform-select-input {
  .form-group {
    margin-top: 0;
  }
}

// radio & checkbox elements
.webform-radios-input,
.webform-checkboxes-input,
.webform-select-input {
  .prefix {
    color: $webform-input-prefix;
    font-size: 20px;
    padding-right: 6px;
  }
  &__remove {
    display: inline-block;
    vertical-align: middle;
  }
}

// select element previews
.webform-select-input {
  padding-left: 30px;
}

// add more entries
.webform-add-more {
  .material-icons {
    float: left;
    margin-right: 6px;
    color: $webform-add-more-icon;
  }
}

// list of webforms
.webforms-drag-placeholder {
  background-color: $webform-drag-placeholder;
}

// mentions component
.mention {
  background-color: $webform-mention-bg;
}

// the actual input
.mentions-input {
  height: auto !important;

  .mentions-input__control {
    font-size: 12px;
    font-weight: normal;
  }

  .mentions-input__input {
    margin: 0;
  }
}

// single line mode
.mentions-input--singleLine {
  .mentions-input__control {
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    width: 130px;
  }

  .mentions-input__input {
    padding: 1;
    border: 0;
    border-bottom: 1px solid $webform-input;
    background-color: transparent;

    .mentions-input__focused {
      border-bottom: 1px solid $webform-input-active;
      box-shadow: 0 1px 0 0 $webform-input-active;
    }
  }
}

// multiline mode
.mentions-input--multiLine {
  .mentions-input__control {
    font-weight: normal;
    font-size: 14px;
  }

  .mentions-input__highlighter {
    padding: 9px 9px 9px 0;
  }

  .mentions-input__input {
    padding: 15px 0;
    outline: 0;
    border: 0;
    background-color: transparent !important;

    .mentions-input__active {
      border-bottom: 1px solid $webform-input-active;
      box-shadow: 0 1px 0 0 $webform-input-active;
    }
  }
}

// suggestions
.mentions-input__suggestions {
  height: 160px;
  margin-top: -160px !important;
  z-index: 1;
  overflow-y: auto;
  box-shadow: 0 2px 5px 0 rgba($webform-shadow-color, 0.25);
  border-radius: 4px;

  .mentions-input__suggestions__item {
    border: 0;
    border-radius: 2px;
    border-bottom: 0 2px 2px 0 rgba($webform-shadow-color, 0.14),
      0 1px 5px 0 rgba($webform-shadow-color, 0.12),
      0 3px 1px -2px rgba($webform-shadow-color, 0.2);
    font-size: 13px;
    line-height: 34px;
    margin: 5px 5px 0;
    padding: 0 10px;
    transition: all 150ms linear;

    &.mentions-input__suggestions__item--focused {
      background-color: $mentions-input-suggestions-item-focused-background-color;
      color: $mentions-input-suggestions-item-focused-color;
      box-shadow: 0 8px 10px 1px rgba($webform-shadow-color, 0.14),
        0 3px 14px 2px rgba($webform-shadow-color, 0.12),
        0 5px 5px -3px rgba($webform-shadow-color, 0.2);
    }
  }
}
