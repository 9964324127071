// timelots table
.atp-time-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  display: block;

  tr {
    border: 0;
  }

  // week separator
  &__last-day-of-week:not(:last-child) {
    border-right: 1px solid $atp-neutral-300;
  }

  // header block
  thead {
    display: block;
    background-color: $atp-neutral-100;
    border-bottom: 2px solid $atp-neutral-300;
    overflow: visible;
  }
  &__day {
    position: relative;
    padding: 0 10px 10px;
    vertical-align: bottom;
    overflow: visible;

    &__name,
    &__date {
      display: block;
      text-align: center;
      line-height: 25px;
      color: $black;
      font-size: 20px;
    }
    &__name {
      @include font-weight-bold;
    }

    &--inactive &__name,
    &--inactive &__date {
      color: rgba($black, 0.2);
    }

    &--today::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 100%;
      max-width: 40px;
      background-color: $atp-brand-primary;
      height: 2px;
      bottom: -1px;
      transform: translate(-50%, 0);
      z-index: 1;
    }
  }

  // layout
  thead th,
  tbody td {
    width: 10%;

    &:first-child {
      width: 15%;
      min-width: 150px;
    }
  }

  // main block
  tbody tr {
    td {
      border-bottom: 1px solid $atp-neutral-100;
      position: relative;
      padding: 0 10px 10px;
      vertical-align: middle;
      background-size: 1px 35px;
      background-position: center bottom;
      text-align: center;
      line-height: 25px;

      &:first-child {
        text-align: left;
        white-space: nowrap;
        padding: 6px 10px 3px 16px;
        font-size: 20px;
        line-height: 25px;
      }
      .atp-input__radios {
        display: inline-block;
        max-width: 20px;
      }
    }

    &:hover td:not([rowspan]) {
      background-color: rgba($atp-neutral-50, 0.5);
    }
  }

  // holiday label
  &__holiday {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $atp-accent-50;
    max-width: 40px;
    transform: translate(-50%, 0);
    overflow: hidden;

    &__label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      color: $atp-accent-700;
      font-size: 16px;
      line-height: 20px;
      @include font-weight-light;
      white-space: nowrap;
    }

    &--too-long &__label {
      display: none;
    }

    &--too-long::before {
      content: '*';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $atp-accent-700;
      font-size: 16px;
      line-height: 20px;
      @include font-weight-light;
      white-space: nowrap;
    }
    @for $i from 1 through 5 {
      &--too-long[data-legend='#{$i}']::before {
        content: '#{$i}*';
      }
    }
  }
}
