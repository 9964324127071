// font family mixins
@mixin font-frutiger() {
  font-family: Frutiger, FrutigerLTW01, Helvetica, Arial, sans-serif;
}

// light
@mixin font-weight-light($important: false) {
  @if ($important) {
    font-weight: 300 !important;
  } @else {
    font-weight: 300;
  }
}
.atp-application .atp-light,
.atp-application .atp-light * {
  @include font-weight-light;
}

// regular
@mixin font-weight-regular($important: false) {
  @if ($important) {
    font-weight: 500 !important;
  } @else {
    font-weight: 500;
  }
}
.atp-application .atp-regular,
.atp-application .atp-regular * {
  @include font-weight-regular;
}

// bold
@mixin font-weight-bold($important: false) {
  @if ($important) {
    font-weight: 700 !important;
  } @else {
    font-weight: 700;
  }
}
.atp-application .atp-bold,
.atp-application .atp-bold * {
  @include font-weight-bold;
}
