.atp-time-tabs {
  @include clearafter;
  margin: 0 !important;

  &__entry {
    font-size: 16px;
    line-height: 20px;
    padding: 0 16px 8px !important;
    @include font-weight-bold;
    position: relative;
    list-style-type: none;
    float: left;
    color: $atp-neutral-700;
    cursor: pointer;
    transition-duration: $transition-duration;
    transition-property: color;

    &:hover {
      color: $black;
    }

    &::before {
      display: none;
    }

    &--current {
      color: $black;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        height: 5px;
        background-color: $atp-neutral-700;
        z-index: 1;
      }
    }
  }
}
