$chat-border-color: #e0e0e0;

[data-behaviour='chat'] {
  align-items: center;
  display: flex;
}

// chat log
.chat.timeline {
  // This rule overrides the ui theme's ".timeline" class for usage in the chat screen
  height: 100%;
  min-height: 100%;

  &::before {
    display: none;
  }

  .files-collection {
    padding: 0;
    margin: 0 0 10px -10px;
  }
}

// chat input area
.chat-input {
  .card {
    margin-top: 0;
    margin-bottom: -10px;
  }

  // .chat-input__editor
  &__editor {
    position: relative;

    .form-group {
      margin: 0;
      padding: 0;
    }

    textarea {
      padding-right: 140px !important;
      margin-top: 10px !important;
      line-height: 2.5rem;
    }
  }

  // .chat-input__editor-actions
  &__editor-actions {
    bottom: 0;
    height: 60px;
    position: absolute;
    right: 0;
  }

  .files-collection {
    margin-top: 0;
    padding: 0;
  }
}

[data-behaviour='message-textblock'] .hidden {
  display: none;
}
