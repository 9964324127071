.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #ddd;

  .progress-bar {
    @include variations(
      unquote('.progress-bar'),
      unquote(''),
      background-color,
      $brand-primary
    );
    box-shadow: none;
  }

  &.progress-line-primary {
    background: rgba($brand-primary, 0.2);
  }

  &.progress-line-info {
    background: rgba($brand-info, 0.2);
  }

  &.progress-line-success {
    background: rgba($brand-success, 0.2);
  }

  &.progress-line-warning {
    background: rgba($brand-warning, 0.2);
  }

  &.progress-line-danger {
    background: rgba($brand-danger, 0.2);
  }
}

.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: $brand-primary;
}
