footer {
  padding: $padding-base 0;

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        color: inherit;
        padding: $padding-base;
        font-weight: $font-weight-bold;
        font-size: $mdb-btn-font-size-base;
        text-transform: uppercase;
        border-radius: $border-radius-base;
        text-decoration: none;
        position: relative;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .copyright {
    padding: 15px;
    margin: 0;

    .material-icons {
      font-size: 18px;
      position: relative;
      top: 3px;
    }
  }

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}
