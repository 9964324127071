$atp-brand-primary: #39499c;
$atp-brand-primary-text: #fff;

$atp-secondary-50: #e1f5fd !default;
$atp-secondary-100: #b3e5fb !default;
$atp-secondary-200: #81d3f8 !default;
$atp-secondary-300: #4fc2f4 !default;
$atp-secondary-400: #29b5f2 !default;
$atp-secondary-500: #00a8f0 !default;
$atp-secondary-600: #009ae1 !default;
$atp-secondary-700: #0087cd !default;
$atp-secondary-800: #0076b9 !default;
$atp-secondary-900: #005698 !default;

$atp-secondary-a100: #afe9fe !default;
$atp-secondary-a200: #77daff !default;
$atp-secondary-a400: #00bfff !default;
$atp-secondary-a700: #0091df !default;

$atp-secondary: $atp-secondary-500 !default;

$atp-black: #000;
$atp-white: #fff;

$atp-neutral-50: #eff2f4;
$atp-neutral-100: #e0e5e8;
$atp-neutral-200: #d3d8dd;
$atp-neutral-300: #c4ccd3;
$atp-neutral-700: #545b68;

$atp-neutral-icon: #939b9f;

$atp-accent-50: #f4f0e6;
$atp-accent-100: #ebe2cd;
$atp-accent-200: #e1d4b3;
$atp-accent-300: #d6c69a;
$atp-accent-700: #888067;
