.wrapper {
  &::after {
    display: table;
    clear: both;
    content: ' ';
  }

  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
  }
}

.full-page {
  &::after,
  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &[filter-color='purple'],
  &[filter-color='primary'] {
    @include lock-page-input-color($brand-primary);

    &::after {
      @include linear-gradient(
        rgba($atp-secondary-100, 0.56),
        rgba($atp-secondary-300, 0.95)
      );
    }
  }

  &[filter-color='blue'],
  &[filter-color='info'] {
    @include lock-page-input-color($brand-info);

    &::after {
      @include linear-gradient(rgba($cyan-100, 0.56), rgba($cyan-300, 0.95));
    }
  }

  &[filter-color='green'],
  &[filter-color='success'] {
    @include lock-page-input-color($brand-success);

    &::after {
      @include linear-gradient(rgba($green-200, 0.56), rgba($green-400, 0.95));
    }
  }

  &[filter-color='orange'],
  &[filter-color='warning'] {
    @include lock-page-input-color($brand-warning);

    &::after {
      @include linear-gradient(
        rgba($orange-100, 0.56),
        rgba($orange-300, 0.95)
      );
    }
  }

  &[filter-color='red'],
  &[filter-color='danger'] {
    @include lock-page-input-color($brand-danger);

    &::after {
      @include linear-gradient(rgba($red-200, 0.56), rgba($red-400, 0.95));
    }
  }

  &[filter-color='rose'] {
    @include lock-page-input-color($brand-rose);

    &::after {
      @include linear-gradient(rgba($pink-100, 0.56), rgba($pink-300, 0.95));
    }
  }

  &[data-image]::after {
    opacity: 0.8;
  }

  > .content,
  > .footer {
    position: relative;
    z-index: 4;
  }

  > .content {
    min-height: calc(100vh - 80px);
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .footer nav > ul a:not(.btn),
  .footer,
  .footer .copyright a {
    color: $white-color;
  }
}

.clear-filter {
  &::before {
    display: none;
  }
}

.login-page,
.lock-page {
  > .content {
    padding-top: 18vh;
  }
}

.login-page {
  .card-login {
    @extend .animation-transition-general;
    @include transform-translate-y(0);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: $border-radius-large;
    padding-bottom: $padding-default-horizontal;

    &.card-hidden {
      @include transform-translate-y(-60px);
      opacity: 0;
    }

    .btn-wd {
      min-width: 180px;
    }

    .card-header {
      margin-top: -40px;
      margin-bottom: 20px;

      .title {
        margin-top: 10px;
      }
    }
  }
}

.lock-page {
  .card-profile {
    @extend .animation-transition-general;
    @include transform-translate-y(0);
    width: 240px;
    margin: 60px auto 0;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    display: block;

    &.card-hidden {
      @include transform-translate-y(-60px);
      opacity: 0;
    }

    .card-avatar {
      max-width: 90px;
      max-height: 90px;
      margin-top: -45px;
    }

    .card-footer {
      border: 0;
      padding-top: 0;
    }

    .form-group {
      text-align: left;

      .form-control {
        background-image: linear-gradient($brand-rose, $brand-rose),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    &.with-animation {
      @include transition(300ms, $transition-ease-in);
    }
  }
}

.register-page {
  .card-signup {
    @include shadow-16dp();
    border-radius: $border-radius-base * 2;
    margin-bottom: 100px;
    padding: 40px 0;

    .info {
      max-width: 360px;
      margin: 0 auto;
      padding: 0;

      .info-title {
        color: #3c4858;
        margin: 30px 0 15px;
      }
    }

    .checkbox {
      margin-top: 20px;
      margin-bottom: 0;

      label {
        margin-left: 17px;
      }

      .checkbox-material {
        padding-right: 20px;
      }
    }

    .input-group {
      .input-group-addon {
        padding-top: 21px;
      }
    }

    .info-horizontal {
      .icon {
        float: left;
        margin-top: 24px;
        margin-right: 10px;

        i {
          font-size: 2.6em;
        }

        &.icon-primary {
          color: $brand-primary;
        }

        &.icon-info {
          color: $brand-info;
        }

        &.icon-success {
          color: $brand-success;
        }

        &.icon-warning {
          color: $brand-warning;
        }

        &.icon-danger {
          color: $brand-danger;
        }

        &.icon-rose {
          color: $brand-rose;
        }
      }

      .description {
        overflow: hidden;
      }
    }

    .form-group {
      margin: 27px 0 0 7px;
      padding-bottom: 0;
    }
  }

  .container {
    position: relative;
    z-index: 3;
    padding-top: 15vh;
  }

  .footer {
    .container {
      padding: 0;
    }
  }
}

.pricing-page {
  .title {
    color: $white-color;
    margin-top: 13vh;
  }

  .section-space {
    display: block;
    height: 70px;
  }

  .card-plain {
    .icon i,
    .card-title {
      color: $white-color;
    }
  }

  .description {
    color: $white-color;
  }

  &.full-page::before {
    background-color: rgba(0, 0, 0, 0.65);
  }
}
