// timeslot table/list header with current week, next and prev CTAs
.atp-time-week {
  position: relative;
  background-color: $atp-neutral-100;
  padding: 16px 64px 8px;

  @include media(m, down) {
    padding: 15px 65px;
  }

  &__prev,
  &__next {
    width: 40px;
    height: 40px;
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    overflow: hidden;
    text-indent: -6000px;
    cursor: pointer;
    @include disable-select;
    background-color: $atp-brand-primary;
    top: 16px;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-color: $atp-brand-primary;
    }

    &:focus {
      @include focus;
    }

    &[disabled] {
      cursor: default !important;
      background-color: $atp-neutral-50 !important;
    }
  }
  &__prev {
    left: 16px;
    background-image: url('~images/leads/tve_reschedule/icons/chevron-left.svg?fill=#{$white}');

    &[disabled] {
      background-image: url('~images/leads/tve_reschedule/icons/chevron-left.svg?fill=#{$atp-neutral-300}');
    }
  }
  &__next {
    right: 16px;
    background-image: url('~images/leads/tve_reschedule/icons/chevron-right.svg?fill=#{$white}');

    &[disabled] {
      background-image: url('~images/leads/tve_reschedule/icons/chevron-right.svg?fill=#{$atp-neutral-300}');
    }
  }

  &__current {
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
