.togglebutton {
  vertical-align: middle;

  &,
  label,
  input,
  .toggle {
    user-select: none;
  }

  label {
    @include mdb-label-color-toggle-focus();
    cursor: pointer;
    color: $mdb-toggle-label-color;

    // Hide original checkbox
    input[type='checkbox'] {
      opacity: 0;
      width: 0;
      height: 0;

      // Handle disabled
      &[disabled] {
        + .toggle::after,
        &:checked + .toggle::after {
          background-color: #bdbdbd;
        }
      }

      + .toggle:active::after,
      &[disabled] + .toggle:active::after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
          0 0 0 15px rgba(0, 0, 0, 0.1);
      }

      // set bg when checked
      &:checked {
        + .toggle {
          background-color: rgba($brand-primary, (70 / 100)); // Switch bg on
        }

        + .toggle::after {
          border-color: $brand-primary; // Handle on
          left: 15px; // Ripple off and disabled
        }

        + .toggle:active::after {
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
            0 0 0 15px rgba($brand-primary, (10 / 100)); // Ripple on
        }
      }
    }

    .toggle {
      text-align: left; // Issue #737 horizontal form
      margin-left: 5px;
    }

    // Switch bg off and disabled
    .toggle,
    input[type='checkbox'][disabled] + .toggle {
      content: '';
      display: inline-block;
      width: 30px;
      height: 15px;
      background-color: rgba(80, 80, 80, 0.7);
      border-radius: 15px;
      margin-right: 15px;
      transition: background 0.3s ease;
      vertical-align: middle;
    }

    // Handle off
    .toggle::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
      left: -5px;
      top: -3px;
      border: 1px solid $mdb-checkbox-border-color;
      transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    }
  }
}
