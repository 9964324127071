// variables / colors
$files-collection-spacing: 10px;
$card-file-width: 190px;
$card-file-height: 90px;

$document-modal-content-background-color: #fff;
$document-modal-dimmer-background-color: #000;

$card-file-close-background-color: #fff;
$card-file-bg: #f5f5f5;
$card-file-text-secondary: #bbb;
$card-file-shadow-color: #000;
$card-file-download: #222;

.files-collection {
  @include clearafter;
  margin: (-$files-collection-spacing) 0 0 (-$files-collection-spacing);

  .card-file {
    background-color: $card-file-bg;
    border-radius: 3px;
    margin: $files-collection-spacing 0 0 $files-collection-spacing;
    float: left;
    width: $card-file-width;
    height: $card-file-height;
    position: relative;
    overflow: visible;
    font-size: 13px;
    line-height: 16px;
    background-size: cover;
    background-position: center;
    padding: 12px 13px 0;
    cursor: pointer;
    transition: box-shadow 0.3s;
    box-shadow: 0 0 0 0 rgba($card-file-shadow-color, 0.09),
      inset 0 -2px 0 0 rgba($card-file-shadow-color, 0.05);

    // @media #{$small-and-down} {
    //   width: 100%;
    // }

    > strong,
    > span {
      display: block;
      font-size: 13px;
      line-height: 16px;
    }

    > span {
      vertical-align: middle;

      // icon override 1
      .material-icons {
        line-height: 16px;
        height: 16px;
        font-size: 20px;
        vertical-align: middle;
      }

      &.secondary {
        color: $card-file-text-secondary;
      }
    }

    // additional direct download link
    .download-link {
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition-duration: 0.2s;
      transition-property: opacity;

      .material-icons {
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: $card-file-download;
      }
    }

    //.card-file:hover
    &:hover {
      box-shadow: 0 2px 8px 0 rgba($card-file-shadow-color, 0.15),
        inset 0 -2px 0 0 rgba($card-file-shadow-color, 0.1);

      .download-link {
        opacity: 1;
      }
    }

    // .card-file--image, image only modifier
    &--image {
      text-indent: -6000px;

      * {
        display: none;
      }
    }

    // remove button for file cards
    .close {
      text-indent: 0;
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      width: 20px;
      height: 20px;
      background-color: $card-file-close-background-color;
      border-radius: 50%;
      transition-duration: 0.2s;
      transition-property: color, background-color;
      z-index: 2;

      // icon override 2
      line-height: 20px;
      font-size: 14px;
      text-align: center;
    }

    &.close:hover {
      display: block !important;
    }
  }
}
