@import 'inputs-size';

@mixin input-group-button-variation($vertical-padding) {
  .input-group-btn {
    .btn {
      margin: 0 0 $vertical-padding;
    }
  }
}

// label variations
.label {
  @include variations(unquote('.label'), unquote(''), background-color, $grey);
  border-radius: $border-radius-small;
}

// must be broken out for reuse - webkit selector breaks firefox
@mixin label-static($label-top, $static-font-size, $static-line-height) {
  label.control-label {
    top: $label-top;
    left: 0;
    // must repeat because the selector above is more specific than the general label sizing
    font-size: $static-font-size;
    line-height: $static-line-height;
  }
}

@mixin label-size-variant(
  $placeholder-font-size,
  $vertical-padding,
  $line-height,
  $static-font-size,
  $static-line-height,
  $help-block-font-size
) {
  .form-control {
    @include material-placeholder {
      font-size: $placeholder-font-size;
      line-height: $line-height;
      color: $mdb-input-placeholder-color;
      font-weight: 400;
    }
  }

  // generic labels used anywhere in the form (not control-label)
  .checkbox label,
  .radio label,
  label,
  .label-on-left,
  .label-on-right {
    font-size: $placeholder-font-size;
    line-height: $line-height;
    color: $mdb-input-placeholder-color;
    font-weight: 400;
  }

  // smaller focused or static size
  label.control-label {
    font-size: $static-font-size;
    line-height: $static-line-height;
    color: $mdb-input-placeholder-color;
    font-weight: 400;
    margin: 16px 0 0; // std and lg
  }

  .help-block {
    margin-top: 0; // allow the input margin to set-off the top of the help-block
    font-size: $help-block-font-size;
  }
}

@mixin form-group-validation-state($name, $color) {
  &.#{$name} {
    // e.g. has-error
    .form-control {
      box-shadow: none;
    }

    &.is-focused .form-control {
      background-image: linear-gradient($color, $color),
        linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
    }

    @if $name == 'has-error' {
      .form-control {
        background-image: linear-gradient($color, $color),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
        background-size: 100% 2px, 100% 1px;
        transition-duration: 0.3s;
      }
    }

    label.control-label,
    .help-block {
      color: $color;
    }
  }
}

@mixin form-group-size-variant(
  $parent,
  $placeholder-font-size,
  $label-top-margin,
  $vertical-padding,
  $line-height,
  $label-as-placeholder-shim
) {
  $static-font-size: ceil(
    ($mdb-label-static-size-ratio * $placeholder-font-size)
  ) !default;
  $static-line-height: ($mdb-label-static-size-ratio * $line-height) !default;

  $label-as-placeholder-top: -1 *
    ($vertical-padding + $label-as-placeholder-shim) !default;
  $label-top: $label-as-placeholder-top -
    ($placeholder-font-size + $vertical-padding) !default;

  $help-block-font-size: ceil(
    ($mdb-help-block-size-ratio * $placeholder-font-size)
  ) !default;
  $help-block-line-height: ($mdb-help-block-size-ratio * $line-height) !default;

  // this is outside a form-group
  @if not $parent {
    @include label-size-variant(
      $placeholder-font-size,
      $vertical-padding,
      $line-height,
      $static-font-size,
      $static-line-height,
      $help-block-font-size
    );
  } @else {
    // this is inside a form-group, may be .form-group.form-group-sm or .form-group.form-group-lg
    #{$parent} {
      @include label-size-variant(
        $placeholder-font-size,
        $vertical-padding,
        $line-height,
        $static-font-size,
        $static-line-height,
        $help-block-font-size
      );

      // form-group padding-bottom
      //  upon collapsing margins, the largest margin is honored which collapses the form-control margin-bottom,
      //  so the form-control margin-bottom must also be expressed as form-group padding
      padding-bottom: $padding-large-vertical;

      // form-group margin-top must be large enough for the label and the label's top padding since label is absolutely positioned
      margin: 8px 0 0;

      // larger labels as placeholders
      &.label-floating,
      &.label-placeholder {
        label.control-label {
          top: $label-as-placeholder-top; // place the floating label to look like a placeholder with input padding
          font-size: $placeholder-font-size;
          line-height: $line-height;
        }
      }

      // static, focused, or autofill floating labels
      &.label-static,
      &.label-floating.is-focused,
      &.label-floating:not(.is-empty) {
        @include label-static(
          $label-top,
          $static-font-size,
          $static-line-height
        );
      }
      // #559 Fix for webkit/chrome autofill - rule must be separate because it breaks firefox otherwise #731
      &.label-floating
        input.form-control:-webkit-autofill
        ~ label.control-label {
        @include label-static(
          $label-top,
          $static-font-size,
          $static-line-height
        );
      }
    }
  }
}

// Inputs
.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient($brand-primary, $brand-primary),
    linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;

  font-weight: 400;

  // Placeholders and and labels-as-placeholders should look the same
  @include material-placeholder {
    color: $mdb-input-placeholder-color;
    font-weight: 400;
  }

  //&:textarea {    // appears to be an invalid selector
  //  height: 40px;
  //}

  &[readonly],
  &[disabled],
  fieldset[disabled] & {
    background-color: rgba(0, 0, 0, 0);
  }

  &[disabled],
  fieldset[disabled] & {
    background-image: none;
    border-bottom: 1px dotted $mdb-input-underline-color;
  }
}

// -----
// Labels with form-group signalled state
//
// Reference http://www.google.com/design/spec/components/text-fields.html
// MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
//.variations(unquote(" label.control-label"), color, $mdb-input-placeholder-color);  // default label color variations

.form-group {
  @include form-group-validation-state(has-warning, $brand-warning);
  @include form-group-validation-state(has-error, $brand-danger);
  @include form-group-validation-state(has-success, $brand-success);
  @include form-group-validation-state(has-info, $brand-info);
  position: relative;

  // -----
  // Labels with form-group signalled state
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
  &.label-static,
  &.label-placeholder,
  &.label-floating {
    label.control-label {
      position: absolute;
      pointer-events: none;
      transition: 0.3s ease all;
    }
  }

  // hint to browser for optimization
  // TODO: evaluate effectiveness - looking for community feedback
  &.label-floating label.control-label {
    will-change: left, top, contents;
  }

  // hide label-placeholders when the field is not empty
  &.label-placeholder:not(.is-empty) {
    label.control-label {
      display: none;
    }
  }

  // Help blocks - position: absolute approach - uses no vertical space, text wrapping - not so good.
  .help-block {
    position: absolute; // do not use position: absolute because width/wrapping isn't automatic and overflows occur
    display: none;
  }

  .form-control {
    &.valid:focus {
      background-image: linear-gradient($brand-success, $brand-success),
        linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
    }
  }

  // form-group is-focused display
  &.is-focused {
    .form-control {
      outline: none;
      background-image: linear-gradient($brand-primary, $brand-primary),
        linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s;

      .material-input::after {
        background-color: $brand-primary;
      }
    }

    &.form-info {
      .form-control {
        background-image: linear-gradient($brand-info, $brand-info),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    &.form-success {
      .form-control {
        background-image: linear-gradient($brand-success, $brand-success),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    &.form-warning {
      .form-control {
        background-image: linear-gradient($brand-warning, $brand-warning),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    &.form-danger {
      .form-control {
        background-image: linear-gradient($brand-danger, $brand-danger),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    &.form-white {
      .form-control {
        background-image: linear-gradient($white-color, $white-color),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }

    //.variations(unquote(".is-focused.label-placeholder label.control-label"), color, $mdb-input-placeholder-color);  // default label color variations
    &.label-placeholder {
      label,
      label.control-label {
        color: $mdb-input-placeholder-color;
      }
    }

    .help-block {
      display: block;
    }
  }

  textarea {
    resize: none;

    ~ .form-control-highlight {
      margin-top: -11px;
    }
  }

  select {
    appearance: none; // Fix for OS X

    ~ .material-input::after {
      display: none;
    }
  }

  //.form-control {
  //  float: none;
  //}

  // sm margin
  &.form-group-sm {
    @include input-group-button-variation($mdb-input-padding-small-vertical);
  }

  // lg margin
  &.form-group-lg {
    @include input-group-button-variation($mdb-input-padding-large-vertical);
  }

  // Input files - hide actual input - requires specific markup in the sample.
  input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}

// default floating size/location without a form-group (will skip form-group styles, and just render default sizing variation)
@include form-group-size-variant(
  null,
  $mdb-input-font-size-base,
  $mdb-label-top-margin-base,
  $mdb-input-padding-base-vertical,
  $mdb-input-line-height-base,
  $mdb-label-as-placeholder-shim-base
);

// default floating size/location with a form-group (need margin etc from a default form-group)
@include form-group-size-variant(
  unquote('.form-group'),
  $mdb-input-font-size-base,
  $mdb-label-top-margin-base,
  $mdb-input-padding-base-vertical,
  $mdb-input-line-height-base,
  $mdb-label-as-placeholder-shim-base
);

// sm floating size/location
@include form-group-size-variant(
  unquote('.form-group.form-group-sm'),
  $mdb-input-font-size-small,
  $mdb-label-top-margin-small,
  $mdb-input-padding-small-vertical,
  $mdb-input-line-height-small,
  $mdb-label-as-placeholder-shim-small
);

// lg floating size/location
@include form-group-size-variant(
  unquote('.form-group.form-group-lg'),
  $mdb-input-font-size-large,
  $mdb-label-top-margin-large,
  $mdb-input-padding-large-vertical,
  $mdb-input-line-height-large,
  $mdb-label-as-placeholder-shim-large
);

select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;

  .form-group.is-focused & {
    box-shadow: none;
    border-color: $mdb-input-underline-color;
  }

  &[multiple] {
    &,
    .form-group.is-focused & {
      height: 85px;
    }
  }
}

// ----------------
// input group/addon related styles

// default margin - no form-group required
@include input-group-button-variation($mdb-input-padding-base-vertical);

.input-group {
  // may be in or outside of form-group
  .input-group-btn {
    padding: 0 12px; // match addon spacing
  }

  .input-group-addon {
    border: 0;
    background: transparent;
    padding: 6px 15px 0;
  }
}

.form-control-feedback {
  opacity: 0;

  .has-success & {
    color: $green;
    opacity: 1;
  }

  .has-error & {
    color: $red;
    opacity: 1;
  }
}
