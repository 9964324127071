.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

  .divider {
    background-color: rgba(0, 0, 0, 0.12);
    margin: 5px 0;
  }

  li {
    position: relative;

    a:hover,
    a:focus,
    a:active {
      background-color: $brand-primary;
      color: #fff;
    }

    > a {
      @include transition($fast-transition-time, $transition-linear);
      font-size: $mdb-dropdown-font-size;
      padding: 10px 20px;
      margin: 0 5px;
      border-radius: $border-radius-small;

      &:hover,
      &:focus {
        @include shadow-8dp();
      }
    }
  }

  &.dropdown-with-icons {
    li > a {
      padding: 12px 20px 12px 12px;

      .material-icons {
        vertical-align: middle;
        font-size: 24px;
        position: relative;
        margin-top: -4px;
        top: 1px;
        margin-right: 12px;
        opacity: 0.5;
      }
    }
  }

  .navbar &,
  .navbar.navbar-default &,
  .bootstrap-table & {
    li {
      a:hover,
      a:focus,
      a:active {
        @include shadow-big-color($brand-primary);
        background-color: $brand-primary;
        color: #fff;
      }
    }
  }
}

.dropdown .dropdown-menu,
.timeline .dropdown .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget,
.bootstrap-table .dropdown-menu {
  @include transition($fast-transition-time, $transition-linear);
  @include transform-translate-y(-20px);
  @include opacity(0);
  visibility: hidden;
  display: block;
}

.dropdown.open .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget.open,
.bootstrap-table .open .dropdown-menu,
.timeline .dropdown.open .dropdown-menu {
  @include opacity(1);
  @include transform-translate-y(1px);
  visibility: visible;
}

.dropup .dropdown-menu {
  @include transition($fast-transition-time, $transition-linear);
  @include transform-translate-y(20px);
  @include opacity(0);
  visibility: hidden;
  display: block;
}

.dropup.open .dropdown-menu {
  @include transform-translate-y(-2px);
  @include opacity(1);
  visibility: visible;
}
