// media queries generation
// ------------------------
// expected values for $size: xxl, xl, l, m, s, xs -> defaul: m
// expected values for $range: up, down, only -> default: only

@mixin media($size: m, $range: only) {
  $lower-limit: nth(get-list($size, $sizes), 2) + px;
  $upper-limit: nth(get-list($size, $sizes), 3) + px;

  // generating from lower limit
  @if ($range == 'up') {
    @media only screen and (min-width: $lower-limit) {
      @content;
    }
  }

  // generating from upper limit
  @if ($range == 'down') {
    @media only screen and (max-width: $upper-limit) {
      @content;
    }
  }

  // generating between both limits
  @if ($range == 'only') {
    @media only screen and (min-width: $lower-limit) and (max-width: $upper-limit) {
      @content;
    }
  }
}

@mixin retina() {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}
