$stage-indicator: #eee;
$stage-indicator-done: #999;

.stage-indicator {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__entry {
    margin: 3px 3px 0 0;
    min-width: 5px;
    -ms-flex-grow: 1;
    flex-grow: 1;
    height: 5px;
    background-color: $stage-indicator;
    border-radius: 2.5px;

    &--done {
      background-color: $stage-indicator-done;
    }
  }
}
