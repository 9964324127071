.appointment-datebox {
  background-color: #f2f2f2;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__month {
    font-size: 14px;
  }

  &__day {
    font-size: 24px;
    font-weight: bold;
  }

  &__year {
    font-size: 10px;
  }
}

.appointment-timeline {
  position: relative;
  list-style: none;
  padding-left: 5px;

  &::before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 1px;
    background-color: #e5e5e5;
  }

  &__item {
    position: relative;
    padding-left: 26px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    position: relative;
    font-weight: 500;

    &::before {
      content: ' ';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: calc(-5px - 26px);
      background-color: #00a5d6;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    }
  }
}

.slot-matrix {
  height: 100%;
  table-layout: fixed;

  td {
    padding: 5px;
  }
}

.profile__topic-list {
  margin-bottom: 0;

  dd:not(:last-child) {
    margin-bottom: 10px;
  }
}

.profile__topic-checkboxes {
  margin-bottom: 0;
}
