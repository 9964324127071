$stage-border-color: #e0e0e0;
$stage-lead-archived-background-color: #fdfdfd;
$stage-lead-archived-color: #999;
$stage-lead-box-shadow-color-hover: rgba(0, 0, 0, 0.15);
$stage-dragover-bg: rgba(0, 0, 0, 0.05);
$ghost-placeholder-border: rgba(0, 0, 0, 0.1);
$ghost-placeholder-background-color: rgba(0, 0, 0, 0.03);
$actions-box-shadow-normal: rgba(0, 0, 0, 0.4);
$actions-box-shadow-over: rgba(0, 0, 0, 0.3);
$actions-move-bg: #444;
$actions-background-color-over: #e2e2e2;
$actions-background-color: #eee;
$actions-height: 120px;
$move-border-color: #ddd;

.pipeline-wrapper {
  float: left;
  min-width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: auto !important;
}

.pipeline-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh);
  max-height: calc(100vh);

  .stage-titles {
    display: -ms-flexbox;
    display: flex;
    // keep fix size
    -ms-flex: 1 1 auto;
    flex: 1;
    height: 45px;

    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;

    clear: both;
    float: left;

    min-width: 100%;
    width: auto !important;

    .stage-title {
      display: flex;
      align-items: center;
      height: 45px;
      -ms-flex-basis: 200px;
      flex-basis: 200px;
      -ms-flex-grow: 1;
      flex-grow: 1;
      font-size: 1.2rem;
      width: 200px;
      min-width: 200px; // super important to keep width equal...
      border-right: 1px solid $stage-border-color;
      border-bottom: 1px solid $stage-border-color;

      div {
        text-align: center;
        vertical-align: middle;
        width: 100%;
      }

      &.over {
        background: $stage-dragover-bg !important;
        transition: all 0.3s ease-out;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  .scroll {
    -ms-flex: 1 1 auto;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    clear: both;
    width: auto;
    float: left;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .vertical-scroll {
    overflow: auto;
    overflow-y: hidden;
    height: 100%;
    min-height: 100%;
  }

  .stages {
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: stretch;

    .owner-name {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: calc(100% - 35px);
    }

    &.drag-mode {
      .stage {
        * {
          // need to disable pointer events on children because it could trigger `dragleave`
          pointer-events: none !important;
        }
      }
    }

    .stage {
      margin: 0;
      -ms-flex-grow: 1;
      flex-grow: 1;
      -ms-flex-basis: 200px;
      flex-basis: 200px;
      width: 200px;
      border-right: 1px solid $stage-border-color;
      min-width: 200px; // NOTE: super important to keep width equal...
      padding: 10px 10px 50px;
      min-height: 100%;

      &:last-child {
        border-right: 0;
      }

      &.over {
        background: $stage-dragover-bg !important;
        transition: all 0.3s ease-out;

        .lead {
          opacity: 0.6 !important;
        }
      }
    }

    .stage-lead {
      background-color: transparent;
      margin-bottom: 10px;
      list-style: none;
      overflow: visible;
      width: 100%;

      // how the element looks like when dragged
      &.ghost-image {
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        cursor: grabbing;
        // opacity: 1 !important;
      }

      // placeholder with dashed border which stays in the stage while dragged
      &.ghost-placeholder {
        background-color: $ghost-placeholder-background-color;
        border-color: $ghost-placeholder-border;
        border-radius: 6px;
        // transition: background-color .3s ease-out;
        > * {
          visibility: hidden;
        }
      }

      &__title {
        font-weight: 400;
        height: auto;
        line-height: 1.2;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      &__product {
        color: color('grey', 'darken-3');
        margin: 0 0 10px;
      }

      &__time {
        color: color('grey', 'lighten-1');
        font-size: 85%;
      }

      .card {
        cursor: pointer;
        margin: 0;
        transition: all 0.1s ease-out;

        &:hover {
          box-shadow: 0 10px 20px $stage-lead-box-shadow-color-hover;
          transition: all 0.15s ease-out;
        }
      }

      &--archived {
        .card {
          background-color: $stage-lead-archived-background-color;
          color: $stage-lead-archived-color;

          .text-muted {
            color: inherit;
          }
        }
      }
    }
  }

  .actions {
    padding: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 260px;
    margin: 0;
    height: $actions-height;
    background-color: $actions-background-color;
    box-shadow: 0 5px 40px $actions-box-shadow-normal;
    transition: all 0.15s ease-out;

    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-around;

    @media only screen and (max-width: 992px) {
      left: 0;
    }

    &.hide {
      display: -ms-flexbox !important;
      display: flex !important;
      bottom: -$actions-height;
      box-shadow: none;
    }

    &.over {
      background-color: $actions-background-color-over;
      padding: 15px;
    }

    .move {
      -ms-flex-grow: 1;
      flex-grow: 1;
      line-height: $actions-height / 2;
      font-size: 1.3rem;
      text-align: center;
      vertical-align: middle;
      border: 2px dashed $move-border-color;
      pointer-events: none;

      * {
        // need to disable pointer events because it could trigger `dragleave`
        pointer-events: none !important;
      }

      &.move {
        background-color: color('grey', 'lighten-6');
        color: $actions-move-bg;
        transition: all 0.15s ease-out;
      }
    }
  }
}
