@charset 'UTF-8';
@import 'ui_overrides';
@import 'mixins';
@import 'global';
@import 'status';
@import 'stage_indicator';
@import 'loading';
@import 'files_collection';
@import 'document_modal';
@import 'chat';
@import 'pipelines';
@import 'webform';
@import 'subnav';
@import 'branches';
@import 'tve';
@import 'material_icons_class_names';

// set body to position relative, required for scroll spy
body {
  position: relative;
}

ul {
  margin: 0;
  padding: 0;
}

samp {
  white-space: pre-line;
}

// integrating statuses into the grid system // FIXME: its a mess
.row,
[class^='col-'] {
  &.status-right,
  &.status-right-outside {
    position: relative;

    > .label {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.status-right {
    padding-right: calc(0.75rem + 24px);

    > .status {
      right: 0.75rem;
    }
  }

  &.status-right-outside {
    @media #{$small-and-down} {
      padding-right: 30px;
    }

    > .label {
      right: 20px;

      @media #{$small-and-down} {
        right: 0.75rem;
      }
    }
  }
}

// table stuff
table {
  &.no-border-bottom > tbody > tr:last-child {
    border-bottom: 0;
  }

  // overrides the uis theme table headers to a
  &.normal-headers {
    th {
      font-size: inherit !important;
    }
  }

  // key value style table formatting
  &.table-key-value {
    th,
    td {
      &:first-child {
        padding-left: 0;
      }
    }

    th:last-child {
      padding-right: 0;
    }

    td:last-child {
      text-align: right;
      padding-right: 0;

      div {
        text-align: left;
      }

      strong {
        font-weight: 500;
      }
    }
  }

  &.not-indented td,
  &.not-indented th {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  // two columns with basic responsiveness
  &.bordered-answer {
    > thead > tr,
    > tbody > tr {
      border-bottom: 1px solid $table-border-color;
    }

    tr,
    td {
      @media #{$small-and-down} {
        display: block;
      }
    }
  }

  // vertical alignment of cell contents
  &.valign-top td {
    vertical-align: top;
  }

  // moving status indicator outside of table cell
  .status-right-of-cell {
    position: relative;

    @media #{$small-and-down} {
      padding-right: 40px !important;
    }

    .label {
      position: absolute;
      right: 5px;

      @media #{$small-and-down} {
        right: 0;
      }
    }
  }
}

// custom navbar rules
// font-size: 33px;
.navbar-header {
  .navbar-brand {
    height: 44px;
    padding: 6px 15px 0;
    line-height: normal;

    .label {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .header-secondary-title {
    color: #aaa; // FIXME: move to color variable.
    font-size: 20px;
  }
}

// custom styles
.ui-avatar {
  width: auto;
  background-color: $brand-primary;
  color: $atp-brand-primary-text;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  font-weight: bold;
  vertical-align: middle;

  img {
    vertical-align: top;
    height: 100%;
    width: 100%;
  }

  &.small {
    font-size: 8px;
    height: 16px;
    width: 16px;
    line-height: 17px;
  }

  &.medium {
    height: 24px;
    width: 24px;
    line-height: 25px;
    font-size: 9px;
  }

  &.sidebar-photo {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 11px;
  }

  &.activity {
    height: 50px;
    width: 50px;
    line-height: 51px;
    font-size: 16px;
  }

  &.large {
    font-size: 42px;
    height: 130px;
    width: 130px;
    line-height: 132px;
  }
}

[data-picture-preview] {
  background: #fff; // FIXME: move to color variable.
  height: 130px;
  width: 130px;
}

[data-search-sort] {
  .material-icons {
    height: 24px;
    width: 24px;
  }

  li a {
    padding: 10px 20px 10px 5px;
  }
}

.label.label-width-2 {
  display: inline-block;
  width: 39px;
}

.leads-flex-wrapper {
  align-items: stretch;
  display: flex;
}

.ui-card__context-actions {
  margin-right: 15px;
  position: absolute;
  right: 0;
  top: -32px;
  z-index: 1;
}

[data-behaviour='leads-actions-menu'] {
  display: none;
}

.sidebar-filter {
  $sidebar-filter-width: 240px;
  -ms-flex-grow: 0;
  flex-grow: 0;
  margin-top: 25px;
  min-width: 0;
  opacity: 0;
  // overflow-x: hidden;
  transition-duration: 0.3s;
  width: 0;

  &.active {
    margin: 25px 0 25px 15px;
    min-width: $sidebar-filter-width;
    opacity: 1;
    width: $sidebar-filter-width;
  }

  .search-submit {
    display: none;
  }
}

.leads-list {
  .messages-indicator {
    font-size: xx-small;
  }
}

.card-content {
  section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    h3:first-child {
      margin-top: 0;
    }

    // h3 {
    //   font-weight: 500;
    // }
  }
}

.lead-wizard {
  .wizard-header {
    padding: 0 20px;

    .wizard-title {
      text-align: left;
    }
  }
}

.table-teams {
  thead tr th {
    font-size: 1.2rem;
    vertical-align: middle;
  }

  &.active {
    display: table;
  }

  &--properties, // .table-teams--properties
  &--users {
    // .table-teams--users
    display: none;
  }
}

.table-of-contents {
  margin-top: 20px;

  &.affix {
    top: 50px;
  }

  li {
    &.active a {
      box-shadow: -2px 0 0 0 $brand-primary;
      font-weight: 500;
    }

    a {
      color: $gray-darker;
      font-size: 12px;

      &:hover {
        background-color: transparent;
        color: $brand-primary;
      }
    }
  }
}

// styles for tbody elements set to the data-sortable attribute
$tbody-sortable-tr-background-color: #fff;
$tbody-sortable-helper-box-shadow-color: rgba(0, 0, 0, 0.15);
$tbody-sortable-placeholder-background-color: rgba(0, 0, 0, 0.1);

[data-sortable] {
  tr {
    @include grab-cursor;
    background-color: $tbody-sortable-tr-background-color;

    &.ui-sortable-helper {
      @include grabbing-cursor;
      box-shadow: 0 10px 30px 0 $tbody-sortable-helper-box-shadow-color,
        0 2px 5px 0 $tbody-sortable-helper-box-shadow-color;

      td {
        border: 0;
      }
    }
  }

  .sortable-placeholder {
    background-color: $tbody-sortable-placeholder-background-color;
    transition: background-color 0.3s ease-out;
  }
}

.list-violations {
  list-style: none;
  margin: 20px 0;

  li {
    display: flex;
    vertical-align: middle;

    i {
      padding-right: 2px;
    }
  }
}

.card.card-lead {
  .card-image {
    height: 250px;

    img {
      object-fit: cover;
    }
  }
}

.activity-input {
  position: relative;

  &__editor {
    .form-group {
      margin: 0;
      padding: 0;
    }

    textarea {
      padding: 0 65px 0 0;
      line-height: 2.5rem;
      height: 25px;
    }
  }

  &__actions {
    bottom: 6px;
    position: absolute;
    right: 0;

    .btn {
      margin: 0;
    }
  }
}
