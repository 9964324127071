@charset 'UTF-8';

@mixin grab-cursor {
  // http://www.google.com/intl/en_ALL/mapfiles/openhand.cur
  cursor: url('data:image/vnd.microsoft.icon;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8='),
    all-scroll;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: -webkit-grab;
  cursor: grab;
}

@mixin grabbing-cursor {
  // http://www.google.com/intl/en_ALL/mapfiles/closedhand.cur
  cursor: url('data:image/vnd.microsoft.icon;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAAH/AAAB/wAAA/0AAANsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//+AB///wAf//4AH//+AD///yT/////////////////////////////8='),
    all-scroll;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

// clear after
@mixin clearafter() {
  &::after {
    content: '.';
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    text-indent: -6000px;
    clear: both;
  }
}

// General transform rotate mixin
@mixin rotate($degrees) {
  $angle: #{$degrees}deg;
  -webkit-transform: rotate($angle);
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -o-transform: rotate($angle);
  transform: rotate($angle);
}
