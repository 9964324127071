.timeline {
  list-style: none;
  padding: 20px 0;
  position: relative;
  margin-top: 30px;

  &::before {
    top: 50px;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 3px;
    background-color: #e5e5e5;
    left: 50%;
    margin-left: -1px;
  }

  h6 {
    color: $gray-dark;
    font-weight: 400;
    margin: 10px 0 0;
  }

  &.timeline-simple {
    margin-top: 30px;
    padding: 0 0 20px;

    &::before {
      left: 5%;
      background-color: #e5e5e5;
    }

    > li > .timeline-panel {
      width: 86%;
    }

    > li > .timeline-badge {
      left: 5%;
    }
  }

  > li {
    margin-bottom: 20px;
    position: relative;

    &::before,
    &::after {
      content: ' ';
      display: table;
    }

    &::after {
      clear: both;
    }

    > .timeline-panel {
      width: 45%;
      float: left;
      padding: 20px;
      margin-bottom: 20px;
      position: relative;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: $border-radius-large;
      color: $mdb-card-body-text;
      background: $mdb-card-body-background;

      &::before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #e4e4e4;
        border-right: 0 solid #e4e4e4;
        border-bottom: 15px solid transparent;
        content: ' ';
      }

      &::after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid $white-color;
        border-right: 0 solid $white-color;
        border-bottom: 14px solid transparent;
        content: ' ';
      }
    }

    > .timeline-badge {
      color: $white-color;
      width: 50px;
      height: 50px;
      line-height: 51px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -24px;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;

      &.primary {
        @include timeline-badge-color($brand-primary);
      }

      &.success {
        @include timeline-badge-color($brand-success);
      }

      &.warning {
        @include timeline-badge-color($brand-warning);
      }

      &.info {
        @include timeline-badge-color($brand-info);
      }

      &.danger {
        @include timeline-badge-color($brand-danger);
      }

      [class^='ti-'],
      [class*=' ti-'],
      [class='material-icons'] {
        line-height: inherit;
      }
    }

    &.timeline-inverted > .timeline-panel {
      float: right;
      background-color: $white-color;

      &::before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &::after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }
}

.timeline-heading {
  margin-bottom: 15px;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
  hr {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .btn {
    margin-bottom: 0;
  }

  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}
