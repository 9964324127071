.atp-time-list {
  list-style-type: none;
  margin: 0 0 15px !important;

  &__entry {
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    margin: 0 0 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      display: none;
    }
  }

  &__radio {
    position: absolute;
    left: -50px;
  }

  &__label {
    background-color: $atp-accent-50;
    line-height: 20px;
    display: block;
    padding: 10px 10px 10px 40px;
    cursor: pointer;
    transition-duration: $transition-duration;
    transition-property: background-color;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      left: 10px;
      top: 10px;
      background-color: $atp-white;
      width: 20px;
      height: 20px;
    }
    &::after {
      left: 14px;
      top: 14px;
      background-color: $black;
      width: 12px;
      height: 12px;
      display: none;
    }
  }
  &__radio:checked + &__label {
    background-color: $atp-accent-300;

    &::after {
      display: block;
    }
  }

  // text labels
  &__timeslot,
  &__location {
    display: block;
    font-size: 15px;
    line-height: 20px;
  }
  &__radio:checked + &__label &__timeslot,
  &__radio:checked + &__label &__location {
    @include font-weight-bold;
  }

  &__holiday {
    background-color: rgba($atp-accent-50, 0.5);
    padding: 25px 15px;
    position: relative;
    min-height: 85px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      display: none;
    }

    &--full-day {
      min-height: 185px;
    }

    &__label {
      color: $atp-accent-700;
      font-size: 15px;
      line-height: 20px;
      display: block;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 15px;
    }
  }
}
