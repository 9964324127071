.popover,
.tooltip-inner {
  @include shadow-8dp();
  color: $gray;
  line-height: 1.5em;
  background: $white-color;
  border: 0;
  border-radius: $border-radius-base;
}

.popover {
  @include shadow-16dp();
  padding: 0;

  &.left,
  &.right,
  &.top,
  &.bottom {
    > .arrow {
      border: 0;
    }
  }
}

.popover-title {
  background-color: $white-color;
  border: 0;
  padding: 15px 15px 5px;
  font-size: $font-size-h4;
}

.popover-content {
  padding: 10px 15px 15px;
  line-height: 1.4;
}

.tooltip {
  @include transform-translate-y(5px);
  opacity: 0;
  transition: opacity, transform 0.2s ease;

  &.in {
    @include transform-translate-y(0);
    opacity: 1;
  }

  &.left {
    .tooltip-arrow {
      border-left-color: $tooltip-arrow-color;
    }
  }

  &.right {
    .tooltip-arrow {
      border-right-color: $tooltip-arrow-color;
    }
  }

  &.top {
    .tooltip-arrow {
      border-top-color: $tooltip-arrow-color;
    }
  }

  &.bottom {
    .tooltip-arrow {
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.tooltip-inner {
  background: $tooltip-bg;
  color: $tooltip-color;
  padding: 8px;
  min-width: 70px;
}
