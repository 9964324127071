@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/material-icons/MaterialIcons-Regular.eot'); // For IE6-8
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('../../fonts/material-icons/MaterialIcons-Regular.woff2')
      format('woff2'),
    url('../../fonts/material-icons/MaterialIcons-Regular.woff') format('woff'),
    url('../../fonts/material-icons/MaterialIcons-Regular.ttf')
      format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; // Preferred icon size
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased; // Support for all WebKit browsers.
  text-rendering: optimizeLegibility; // Support for Safari and Chrome.
  -moz-osx-font-smoothing: grayscale; // Support for Firefox.
  font-feature-settings: 'liga'; // Support for IE.
}
