body {
  background-color: #eee;
  // background: #fdfdfe;
  color: $black-color;

  &.inverse {
    background: #333;

    &,
    .form-control {
      color: $mdb-text-color-light;
    }

    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }
  }
}

blockquote {
  p {
    font-style: italic;
  }
}

.life-of-material-dashboard {
  background: #fff;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

.serif-font {
  font-family: $font-family-serif;
}

.page-header {
  height: 60vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

a {
  color: $link-color;

  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      color: darken($brand-info, 5%);
    }
  }

  .material-icons {
    vertical-align: middle;
  }
}

a[data-toggle='collapse'][aria-expanded='true'] .caret,
.dropdown.open .caret,
.dropup.open .caret,
.btn-group.bootstrap-select.open .caret {
  @include rotate-180();
}

.caret,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role='remove'] {
  @include transition($fast-transition-time, $transition-ease-in);
}

// Animations
.animation-transition-general {
  @include transition($general-transition-time, $transition-linear);
}

.animation-transition-slow {
  @include transition($slow-transition-time, $transition-linear);
}

.animation-transition-fast {
  @include transition($fast-transition-time, $transition-ease);
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  // -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0;
  }
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0 !important;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
}
