.navbar {
  @extend .animation-transition-fast;

  // SASS conversion note: please mirror any content change in _mixins-shared.scss navbar-variations-content
  @include navbar-variations(unquote('.navbar'), unquote(''), $brand-primary);

  // @include shadow-big-navbar();
  // background-color: $brand-primary;
  border: 0;
  border-radius: $border-radius-base;
  border-bottom: 1px solid #ededf3;

  padding: 10px 0;

  .navbar-brand {
    position: relative;
    height: 50px;
    line-height: 30px;
    color: inherit;
    padding: 10px 15px;

    &:hover,
    &:focus {
      color: inherit;
      background-color: transparent;
    }
  }

  .navbar-minimize {
    float: left;
    padding: 3px 0 0 15px;
  }

  .notification {
    position: absolute;
    top: 5px;
    border: 1px solid #fff;
    right: 10px;
    font-size: 9px;
    background: #f44336;
    color: #fff;
    min-width: 20px;
    padding: 0 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    // vertical-align: middle;
    display: block;
  }

  .navbar-text {
    color: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .navbar-nav {
    > li > a {
      color: inherit;
      padding-top: 15px;
      padding-bottom: 15px;

      font-weight: $font-weight-bold;
      font-size: $mdb-btn-font-size-base;
      text-transform: uppercase;

      border-radius: $border-radius-base;

      &:hover,
      &:focus {
        color: inherit;
        background-color: transparent;
      }

      .material-icons,
      .fa {
        font-size: 20px;
      }

      &.btn:not(.btn-just-icon) {
        .fa {
          position: relative;
          top: 2px;
          margin-top: -4px;
          margin-right: 4px;
        }
      }
    }

    > li > .dropdown-menu {
      @include transform-translate-y(-20px);
    }

    > li.open > .dropdown-menu {
      @include transform-translate-y(0);
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        color: inherit;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: inherit;
        background-color: transparent;
        opacity: 0.9;
      }
    }

    .profile-photo {
      padding: 0 5px;

      .profile-photo-small {
        height: 40px;
        width: 40px;
      }
    }

    // Dropdowns
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: transparent;
        color: inherit;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .navbar-text {
        color: inherit;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      // Dropdowns get custom display
      .open .dropdown-menu {
        > .dropdown-header {
          border: 0;
          color: inherit;
        }

        .divider {
          border-bottom: 1px solid;
          opacity: 0.08;
        }

        > li > a {
          color: inherit;

          &:hover,
          &:focus {
            color: inherit;
            background-color: transparent;
          }
        }

        > .active > a {
          &,
          &:hover,
          &:focus {
            color: inherit;
            background-color: transparent;
          }
        }

        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: inherit;
            background-color: transparent;
          }
        }
      }
    }
  }

  // Darken the responsive nav toggle
  .navbar-toggle {
    border: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    .icon-bar {
      background-color: inherit;
      border: 1px solid;
    }
  }

  .navbar-default .navbar-toggle,
  .navbar-inverse .navbar-toggle {
    border-color: transparent;
  }

  .navbar-collapse,
  .navbar-form {
    border-top: 0;
    box-shadow: none;
  }

  &.navbar-default {
    .logo-container .brand {
      color: $gray;
    }
  }

  .navbar-link {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-link {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }

    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }

  .navbar-form {
    margin: 4px 0 0;

    .form-group {
      margin: 0;
      padding: 0;

      .material-input::before,
      &.is-focused .material-input::after {
        background-color: inherit;
      }
    }

    .form-group .form-control,
    .form-control {
      border-color: inherit;
      color: inherit;
      padding: 0;
      margin: 0;

      // re-normalize inputs in a navbar the size of standard bootstrap since our normal inputs are larger by spec than bootstrap
      //---
      //height: $mdb-input-height-base;
      $bs-line-height-base: 1.428571429 !default;
      $bs-line-height-computed: floor(
        ($font-size-base * $bs-line-height-base)
      ) !default; // ~20px
      height: ($bs-line-height-computed + 8px);
      font-size: $font-size-base;
      line-height: $bs-line-height-base;
      //---
    }
  }

  &-inverse {
    background-color: $indigo;
  }

  &.navbar-transparent {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 0;

    .logo-container .brand {
      color: $white-color;
    }
  }

  &-fixed-top {
    border-radius: 0;
  }

  @media (max-width: $screen-md-max) {
    .navbar-brand {
      height: 50px;
      padding: 10px 15px;
    }

    // .navbar-form {
    //   margin-top: 10px;
    // }

    .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .alert {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3;
    transition: all 0.3s;
  }
}

.nav-center {
  text-align: center;

  .nav-pills-icons {
    display: inline-block;
  }
}

.nav-align-center {
  text-align: center;

  .nav-pills {
    display: inline-block;
  }
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
