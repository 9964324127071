@import "../material-dashboard/variables";
@import "../material-dashboard/shadows";

// NOTE: overriding rules to the material-dashboard-pro ui are kept on this separate scss partial

// Fixed issue: sidebar would stay under some content when sliding in some conditions.
.sidebar {
  z-index: 1030;

  .sidebar-wrapper {
    overflow-y: auto;
  }
}

// Fixed issue: using btn-default would not retain a contrasting background color, showing a white background
.dropdown-toggle.btn-default[aria-expanded="true"] {
  background-color: #888 !important;
}

// Fixed issue: perfected square dimensions of buttons using btn-round and btn-just-icon
.btn-round.btn-just-icon {
  border-radius: 50% !important;
  height: 44px;
  width: 44px;
}

// Issue: changed font-size:
.navbar-brand {
  font-size: 30px;
}

// Fixed issue: minor navbar layout margin 0 correction to keep buttons right aligned with the content
.navbar-right {
  margin-right: 0;
}

// Fixed issue: changed line-height so that ".label" items won't overlap between different lines
// Used within .list-activities (tim)
.timeline {
  > li > .timeline-badge.timeline-badge-avatar {
    line-height: 48px;
    background-color: #999;
    box-shadow: 0 4px 20px 0 rgba(#000, 0.14), 0 7px 10px -5px rgba(#000, 0.4);
  }

  &.timeline-simple {
    &::before {
      left: 5%;
      background-color: #e5e5e5;
    }

    > li:not(.timeline-inverted) > .timeline-badge {
      right: 2%;
      left: auto;
    }

    &.list-activities {
      margin-top: 0;

      > li {
        margin: 0;

        > .timeline-badge {
          top: 6px;
        }

        > .timeline-panel {
          padding: 10px 14px;
          width: 88%;

          &::before {
            top: 16px;
          }

          &::after {
            top: 17px;
          }

          .timeline-body {
            line-height: 1.65em;
          }
        }
      }
    }
  }
}

.card {
  // Fixed issue: make the card-product and card-profile classes match the same margin as other cards.
  &.card-product,
  &.card-profile {
    margin: 25px 0;
  }

  &.card-product {
    .card-image {
      margin-top: -20px;
    }
  }

  &.card-login i {
    display: inline-block;
  }

  .card-header {
    &.card-header-small {
      padding: 0;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(114, 82, 172, 0.4);
    }
  }

  .card-avatar {
    margin: -40px auto 0;
    font-size: 40px;
    line-height: 123px;
  }

  .card-content {
    padding: 20px;

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child {
      margin-top: 0;
    }
  }

  // Fixed issue: remove unbalanced measures.
  .card-footer {
    margin: 0 20px 20px;
    padding-top: 20px;

    .btn {
      margin: 0;
    }
  }

  .nav-pills,
  .tab-content {
    margin-top: 0;
  }
}

// Fixed issue: useless margin

p .material-icons {
  font-size: $font-size-base;
  vertical-align: middle;
}

small .material-icons {
  font-size: $font-size-small;
  line-height: $line-height-small;
  vertical-align: middle;
}

// Fixed issue: remove useless margin-bottom for any element with the .btn class inside the .modal-footer
.modal-footer .btn {
  margin: 0;
}

// Fixed issue: allow a primary color for the data attribute styling of headers in cards
[data-background-color="primary"] {
  @include shadow-big-color($brand-primary);
  background: linear-gradient(
    60deg,
    $brand-primary,
    darken($brand-primary, 20%)
  );
}

// Fixed issue: tweaking the background color of the gray label
.label {
  &.label-default {
    background-color: $gray-label;
  }
}

// Fixed issue: added extra / non-existing rules
.tooltip-inner {
  font-size: 10px;
  font-weight: bold;
}

// Fixed issue: increase width of input type search
.form-search [type="search"] {
  width: 200px;
}

// Fixed issue: showing the caret symbol with this type of select styles helps to identify better the component in
// the form, specially when there is only one visible.
.select-with-transition .caret {
  display: inline-block;
}

// Fixed issue: showing the caret symbol with this type of select styles helps to identify better the component in
// the form, specially when there is only one visible.
.bootstrap-select.btn-group .dropdown-toggle.select-with-transition .caret {
  right: 6px;
}

// Fixed issue: we are overriding the customized bootstrap panels, for better fitting/visibility and actual usage on the app
.panel {
  // background-color: transparent; // FIXME: overriden
  border: 1px solid #ddd;

  .panel-heading {
    background-color: #f9f9f9; // FIXME: overriden
    border-bottom: 1px solid #ddd;
    // padding: 25px 10px 5px 0; // FIXME: overriden
    padding: 10px 15px;

    .panel-title {
      font-size: 15px;
      font-weight: bolder;
    }
  }

  .panel-body {
    border: 0;
    padding: 15px;
  }

  &.panel-default {
    .panel-heading + .panel-collapse {
      .panel-body {
        border: 0;
      }
    }
  }
}

// Fixed issue: was not showing the Bootstrap .help-block class
.form-group .help-block {
  display: block;
  position: relative;
}

// Fixed issue: the color of the labels looked like disabled text, hence changed to the black color used in cards.
.radio label,
.checkbox label {
  color: $mdb-card-body-text !important;
}

fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  color: $mdb-label-color !important;
}

// Fixed issue: annoyingly, the radio button was rendered at 15x15px area, while the checkbox was 20x20px.
// This makes it 20x20 as well and adjusts the positioning.
.radio label {
  padding: 3px 0 0 25px;

  span {
    left: 0;
  }

  .circle {
    width: 20px;
    height: 20px;
  }

  .check {
    left: 2px;
    top: 4px;
    height: 16px;
    width: 16px;
  }
}

// Fixed issue: the select component had an unreasonable top margin of 10px, when having it placed side-by-side in
// columns, against other form components. Fine-tuned to 2px.
.form-group .bootstrap-select.btn-group {
  margin-top: 2px;
}

// REVIEW: experimental change to the navbar
.main-panel > .content {
  margin: 0;
}

.navbar.navbar-bordered {
  min-height: 74px;
  border-bottom: 1px solid #dbdbdb !important;

  &.navbar-transparent {
    background-color: #f6f6f6;
  }

  &.navbar-absolute {
    position: relative;
  }

  &.container-fluid .navbar-header .nav-brand {
    height: auto !important;
  }
}

// Fixed issue: turn off visibility of help-block, if the form-group does not have the has-error class
.help-block {
  visibility: hidden;
}

.form-group.has-error .help-block--error {
  visibility: visible;
}

.form-group.has-hint .help-block--hint {
  visibility: visible;
}

// Fixed issue: better readability of disabled input
.form-group .form-control[disabled] {
  border-bottom-style: dashed;
  color: $mdb-input-placeholder-color;
  cursor: default;
}

// Fixed issue: make the button used on bootstrap-select to have similar styles to a basic themed <input type="text">
.bootstrap-select .btn.select-with-transition {
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
}

.nav-pills {
  &:not(.nav-pills-icons) > li > a {
    border-radius: $border-radius-base;
  }
}

// Fixed issue: Addition to bootstrap: class modifier for scrollable modal body
.modal-body--scrollable {
  height: 400px;
  overflow-y: auto;
}

// Fixed issue: buttons affected by the bootstrap .btn-group-vertical class were being given different border radius.
// Applying top left + top right border radius of 2px, to first button, if not last.
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

// Applying bottom left + bottom right border radius of 2px, to last button, if not first.
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

// Fixed issue: increased readability of focused text input by coloring its label.
.form-group.is-focused:not(.has-error) .control-label:not(.radio_buttons) {
  color: $brand-primary !important;
}

// force links to be underlined when we have a content-editable, as it helps the UI to be more understandable/navigatable.
.form-control.content-editable a {
  text-decoration: underline;
}
