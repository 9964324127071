.label {
  border-radius: $border-radius-label;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;

  &.label-default {
    background-color: $gray-light;
  }
}
