// import the variables, so we can access the ui theme colors
@import '../material-dashboard/variables';

$status-color-text-white: #fff;

// The following color variables for the statuses, are linking to the ui theme's main color variables.
$status-color-names: (
  'grey': $brand-default,
  'green': $brand-success,
  'orange': $brand-warning,
  'red': $brand-danger,
);

// Using complementary status classes to Bootstrap .text-* | .label-* class colors
// because of our app's semantics,
@each $k, $v in $status-color-names {
  .text-#{$k} {
    color: #{$v};
  }

  .label {
    &.label-#{$k} {
      background-color: #{$v};
    }
  }

  .alert-#{$k} {
    i {
      color: #{$v};
    }

    &.alert-#{$k} {
      background-color: #{$v};
      color: $status-color-text-white;
    }
  }
}
