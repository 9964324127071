.atp-timetable-legend {
  counter-reset: entry;
  list-style: none;
  padding-left: 16px;
  margin-top: 20px;

  &__entry {
    color: $atp-accent-700;
    font-size: 16px;
    left: 9px;
    line-height: 20px;
    padding-bottom: 5px;
    position: relative;

    &:before {
      content: counter(entry);
      counter-increment: entry;
      font-size: xx-small;
      position: absolute;
      left: -6px;
      top: -5px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
