.business-hours-input {
  a[data-behaviour='shrink-business-hours-input'] {
    display: none;
  }

  .business-hours-input__closed-notice {
    display: none;
  }

  .expandable {
    display: none;
  }

  .business-hours-input__ranges {
    .form-control {
      display: inline;
    }

    .form-group {
      display: inline;

      &.expandable {
        display: none;
      }
    }
  }
}

.business-hours-input--expanded {
  a[data-behaviour='expand-business-hours-input'] {
    display: none;
  }

  .business-hours-input__closed-notice {
    display: none;
  }

  .business-hours-input__ranges {
    .form-control {
      display: inline;
    }

    .form-group {
      display: inline;

      &.expandable {
        display: inline;
      }
    }
  }
}

.business-hours-input--closed {
  .business-hours-input__ranges {
    display: none;
  }

  .business-hours-input__closed-notice {
    line-height: 45px;
  }
}

.business-hours-input__ranges {
  &.has-error .error-block {
    color: #e40531;
    font-size: 11px;
  }

  input[type='text'] {
    width: auto;
    text-align: center;
  }
}

dl.business-hours {
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 33%;
  }

  dd {
    margin-left: auto;
    width: 66%;
  }
}

.expandable-checkboxes__list {
  &.expandable-checkboxes--collapsed .checkbox:not(.checked) {
    display: none;
  }

  &:not(.expandable-checkboxes--none-selected)
    .expandable-checkboxes__placeholder {
    display: none;
  }

  &.expandable-checkboxes--expanded .expandable-checkboxes__placeholder {
    display: none;
  }
}

.expandable-checkboxes__trigger {
  &.expandable-checkboxes--collapsed {
    .expandable-checkboxes--expanded {
      display: none;
    }
  }

  &.expandable-checkboxes--expanded {
    .expandable-checkboxes--collapsed {
      display: none;
    }
  }
}
