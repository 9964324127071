.panel {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;

  .panel-heading {
    background-color: transparent;
    border-bottom: 1px solid #ddd;
    padding: 25px 10px 5px 0;

    .panel-title {
      font-size: 15px;
      font-weight: bolder;
    }

    a {
      color: $black-color;

      &:hover,
      &:active,
      &[aria-expanded='true'] {
        color: $brand-rose;
      }

      &[aria-expanded='true'],
      &.expanded {
        .panel-title > i {
          @include rotate-180();
        }
      }
    }

    i {
      @extend .animation-transition-fast;
      float: right;
    }
  }

  .panel-body {
    border: 0 none;
    padding: 15px 0 5px;
  }

  &.panel-default {
    .panel-heading + .panel-collapse {
      .panel-body {
        border: 0 none;
      }
    }
  }
}
