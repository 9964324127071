// variables / colors
$document-modal-content-background-color: #fff;
$document-modal-title-color: $gray;
$document-modal-dimmer-background-color: #000;

// basic modal wrapping
.document-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  // modal bg dimmer
  &__dimmer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($document-modal-dimmer-background-color, 0.7);
  }

  // header bar
  &__header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba($document-modal-dimmer-background-color, 0.5);
    padding: 0 8px;
    z-index: 2;

    .left {
      padding-right: 10px;
    }

    .right:last-of-type {
      padding-right: 40px;
    }
  }

  &__title {
    color: $document-modal-title-color;
    margin: 15px 0;
  }

  // modal content
  &__content {
    position: fixed;
    left: 50%;
    top: 55%;
    overflow: auto;
    max-width: 100%;
    max-height: calc(100% - #{(2.4 * $navbar-height)});
    transform: translate3d(-50%, -50%, 0);
    background-color: $document-modal-content-background-color;
    z-index: 1;
    box-shadow: 0 2px 4px rgba($document-modal-dimmer-background-color, 0.7);

    .pdfobject-container {
      height: 100%;
    }

    iframe,
    embed {
      height: 100%;
      border: 0;
      margin: 0;
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .no-preview {
      padding: 24px;
    }
  }

  // left & right controls
  &__prev,
  &__next {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%, 0);
    padding: 0 1.5rem;
    z-index: 3;

    .material-icons {
      font-size: 32px;
    }
  }

  &__next {
    right: 1rem;
  }

  &__prev {
    left: 1rem;
  }
}
