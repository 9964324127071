$inactive-text: #9c9fa1;
$inactive-bg: #e9ecee;

.atp-time-header {
  background-color: $atp-neutral-100;
  padding: 0 15px !important;
  -ms-display: flex;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  list-style-type: none;

  // entries
  &__entry {
    padding: 5px 0 !important;
    width: 100% / 7;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
    border-right: 1px solid $atp-neutral-300;

    &:first-child {
      border-left: 1px solid $atp-neutral-300;
    }

    &::before {
      display: none;
    }

    &--current {
      background-color: $atp-white;
    }

    &--dimmed {
      background-repeat: repeat !important;
      background-image: url('~images/leads/tve_reschedule/time/header-bg@2x.png');
      background-size: 6px 6px;
    }
  }

  // text labels
  &__day,
  &__date,
  &__slots {
    text-align: center;
    display: block;
    font-size: 15px;
    line-height: 16px;
    cursor: pointer;
  }

  &__day {
    @include font-weight-bold;
    margin: 0 0 1px;
  }
  &__date {
    margin: 0 0 5px;
  }
  &__slots {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    background-color: $atp-white;
  }

  // inactive state
  &__entry--inactive &__day,
  &__entry--inactive &__date,
  &__entry--inactive &__slots {
    color: $inactive-text;
  }
  &__entry--inactive &__slots {
    background-color: $inactive-bg;
  }

  // current state
  &__entry--current &__slots {
    background-color: $atp-brand-primary;
    color: $atp-white;
  }
}

// wrapper with fixed mode
.atp-time-header-wrapper--fixed {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 20px $black;

  .atp-time-header {
    margin-bottom: 0;
  }
}
